import React, { useEffect, useState, useRef } from "react";
import { Box, Typography, Button, RadioGroup, FormControl, FormControlLabel, CircularProgress, Modal, Radio, Checkbox, FormGroup, Divider, TextField, InputAdornment, IconButton, Autocomplete } from "@mui/material";
import BoxContainer from "./BoxContainer";
import MyChip from "./MyChip";
import AttenteIcon from "../../imgs/imgV2/attente.svg";
import iconPoint from "../../imgs/imgV2/point.svg";
import arrowRightIcon from "../../imgs/imgV2/arrowRightBlavk.svg";
import * as Yup from "yup";
import moment from "moment";
import checkLinkicon from "../../imgs/imgV2/checkLinkIcon.svg";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import RechercheIcon from "../../imgs/imgV2/RechercheIcon.svg";
import uploadIcon from "../../imgs/imgV2/upload-icon.svg";
import ButtonSquare from "./ButtonSquare";
import { modalStyle, modalStyle2 } from "../../theme/ModalStyle";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { colors } from "../../theme/Colors";
import MyTextField from "./MyTextField";
import Api from "../../api/Api";
import Xredicon from "../../imgs/imgV2/XredIcon.svg";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Urls } from "../../api/Urls";
import FormikErrorText from "./FormikErrorText";
import DetailOffreFinale from "../../pages/candidat/content/DetailOffreFinale";
import { toast } from "react-toastify";
import MyDP from "./MyDP";
import MyDPFullDate from "./MyDPFullDate";
function CandidatureComponent({ change, setChange, motif, userF, loading, missing_documents, RejeterCandidature, id, AnnulerCandidature, AccepterCandidature, openAccepter, setOpenAccepter, contrat, possibleActions, etat, offreID, color, matchingLevel, offreName, createdAt, start_date, end_date, hourly_rate, openAnnuler, setOpenAnnuler, openRefuser, setOpenRefuser, address }) {
  const [showPasswordDel, setShowPasswordDel] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const FILE_SIZE = 5000 * 1024;
  const [certification, setCertification] = useState([]);

  function handleClickShowPasswordDel() {
    setShowPasswordDel(!showPasswordDel);
  }
  function handleMouseDownPasswordDel(event) {
    event.preventDefault();
  }
  const maxSize = 5 * 1024 * 1024;

  //console.log(createdAt, "dates kharej");
  const { user } = useAuthContext();
  const identityCard = useRef();
  const passport = useRef();
  const justifDom = useRef();
  const [error, setError] = useState("");
  const [isFileVerified, setIsFileVerified] = useState(false);
  const residencePermit = useRef();
  const residenceReceipt = useRef();
  const rib = useRef();
  const [drag, setDrag] = useState(false);
  const secSocial = useRef();
  const [idCert, setIDCert] = useState();
  const [idScolar, setIDScolar] = useState();
  const [scolar, setScolar] = useState([]);
  const formikRef = useRef();
  const [miss, setMiss] = useState(missing_documents);
  const [openDropContrat, setOpenDropContrat] = useState({ open: false, data: contrat });
  const [file, setFile] = useState();
  const access_token = user?.access_token;
  const [openDocs, setOpenDOCs] = useState(false);

  function afficherDifferenceDate(date) {
    const now = moment();
    const diff = moment(date);
    const minutes = now.diff(diff, "minutes");
    const hours = now.diff(diff, "hours");
    const jours = now.diff(diff, "days");
    const semaines = now.diff(diff, "weeks");
    const mois = now.diff(diff, "months");
    //console.log(createdAt, "dates");
    if (minutes === 1) {
      return "Envoyée il y a 1 minute";
    } else if (minutes > 1 && minutes < 60) {
      return `Envoyée il y a ${minutes} minutes`;
    }
    if (hours === 1) {
      return "Envoyée il y a 1 heure";
    } else if (hours > 1 && hours < 24) {
      return `Envoyée il y a ${hours} heures`;
    }
    if (jours === 1) {
      return "Envoyée il y a 1 jour";
    } else if (jours > 1 && jours < 7) {
      return `Envoyée il y a ${jours} jours`;
    } else if (semaines === 1) {
      return "Envoyée il y a 1 semaine";
    } else if (semaines > 1 && semaines < 4) {
      return `Envoyée il y a ${semaines} semaines`;
    } else if (mois === 1) {
      return "Envoyée il y a 1 mois";
    } else {
      return `Envoyée il y a ${mois} mois`;
    }
  }
  const VerifyFile = (e) => {
    if (e.target?.files[0]) {
      if (!e.target?.files[0].size <= maxSize) {
        setError("File size > 5MB");
        setIsFileVerified(false);
      }
      if (e.target?.files[0].size <= maxSize) {
        setError("");
        setIsFileVerified(true);
        setFile(e.target?.files[0]);
      }
    } else {
      setFile(null);
    }
  };
  function afficherDifferenceDateHeurs(date) {
    const now = moment();
    const diff = moment(date);
    const hours = now.diff(diff, "hours");
    return hours;
  }
  const chipSelector = (etat) => {
    switch (etat) {
      case "APPLIED":
        return "En attente";
        break;
      case "PENDING":
        return "En attente";
        break;
      case "OFFER_REJECTED":
        return "Offre rejetée";
        break;
      case "APPLICATION_REJECTED":
        return "Candidature rejetée";
        break;
      case "APPLICATION_CANCELED":
        return "Candidature Annulée";
        break;
      case "WAITING_SIGNATURE":
        return "En attente de signature";
        break;
      case "CONTRACT_IN_CREATION":
        return "En cours de création";
        break;
    }
  };
  const actionSelector = (action) => {
    switch (action) {
      case "ACCEPTED":
        return "mj_green_md";
        break;
      case "REFUSED":
        return "mj_red_md";
        break;
      case "CANCELED":
        return "mj_red_md";
        break;
      case "WAITING_INTERIM_SIGNATURE":
        return "mj_green_md";
        break;
    }
  };
  const handleDrop = () => {
    setDrag(false);
  };
  const handleDragOver = () => {
    setDrag(true);
  };
  const handleDragLeave = () => {
    setDrag(false);
  };
  const actionTranslate = {
    REFUSED: "Refuser",
    ACCEPTED: "Accepter",
    CANCELED: "Annuler",
    SIGNED: "Signer",
  };
  // validation schema carte identité
  const validationSchemaCarteIdent = Yup.object({
    piece_did_recto: Yup.mixed()
      .required("Veuillez selectionner un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE),
    piece_did_verso: Yup.mixed()
      .required("Veuillez selectionner un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE),
    num_carte: Yup.number().typeError("Valeur invalide").required("Ce champ est oblgatoire").positive("Numéro invalide"),
    date_exp_carte: Yup.date().required("Ce champ est obligatoire"),
    lieu_de_naissance: Yup.string().required("Ce champ est obligatoire"),
  });
  // validation schema carte identité update
  const validationSchemaCarteIdentUpdate = Yup.object({
    num_carte: Yup.number().typeError("Valeur invalide").required("Ce champ est oblgatoire").positive("Numéro invalide"),
    date_exp_carte: Yup.date().required("Ce champ est obligatoire"),
    lieu_de_naissance: Yup.string().required("Ce champ est obligatoire"),
  });
  //
  //
  // validation schema passeport
  const validationSchemaPasseport = Yup.object({
    num_passport: Yup.number().typeError("Valeur invalide").required("Ce champ est oblgatoire").positive("Numéro invalide"),
    date_em_passport: Yup.date().required("Ce champ est obligatoire"),
    date_exp_passport: Yup.date().required("Ce champ est obligatoire"),
    file_passport: Yup.mixed()
      .required("Veuillez selectionner un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE),
    lieu_de_naissance: Yup.string().required("Ce champ est obligatoire"),
  });
  //
  // validation schema passeport update
  const validationSchemaPasseportUpdate = Yup.object({
    num_passport: Yup.number().typeError("Valeur invalide").required("Ce champ est oblgatoire").positive("Numéro invalide"),
    date_em_passport: Yup.date().required("Ce champ est obligatoire"),
    date_exp_passport: Yup.date().required("Ce champ est obligatoire"),
    lieu_de_naissance: Yup.string().required("Ce champ est obligatoire"),
  });
  //
  //
  //
  // validation schema titre de sejour
  const validationSchemaTitreSejour = Yup.object({
    sejour_recto: Yup.mixed()
      .required("Veuillez selectionner un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE),
    sejour_verso: Yup.mixed()
      .required("Veuillez selectionner un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE),
    lieu_de_naissance: Yup.string().required("Ce champ est obligatoire"),
  });
  //
  // validation schema titre de sejour udate
  const validationSchemaTitreSejourUpdate = Yup.object({
    lieu_de_naissance: Yup.string().required("Ce champ est obligatoire"),
  });
  //
  //
  // validation schema res
  const validationSchemaRes = Yup.object({
    piece_res: Yup.mixed()
      .required("Veuillez selectionner un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE),
    lieu_de_naissance: Yup.string().required("Ce champ est obligatoire"),
  });
  // validation schema res updqte
  const validationSchemaResUpdate = Yup.object({
    lieu_de_naissance: Yup.string().required("Ce champ est obligatoire"),
  });
  /// validation Schema rib
  const validationSchemaRIb = Yup.object({
    rib: Yup.number().typeError("Valeur invalide").required("Ce champ est oblgatoire").positive("Numéro invalide"),
  });
  // validation shema justificatif domicile
  const validationSchemaJustificatifDomicile = Yup.object({
    justificatif_domicile: Yup.mixed()
      .required("Veuillez selectionner un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE),
  });

  //
  //
  // VALIDATION SCHEMA SECURITE SOCIAL
  const validationSchemaSecuSocial = Yup.object({
    justificatif_sec_social: Yup.mixed()
      .required("Veuillez selectionner un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE),
    num_sec_social: Yup.number().typeError("Valeur invalide").required("Ce champ est obligatoire").positive("Numéro invalide"),
  });

  // VALIDATION SCHEMA SECURITE SOCIAL update
  const validationSchemaSecuSocialUpdate = Yup.object({
    num_sec_social: Yup.number().typeError("Valeur invalide").required("Ce champ est obligatoire").positive("Numéro invalide"),
  });
  //validation schema fot the form
  const validationSchema = Yup.object().shape({
    titre: Yup.string().required("Ce champ est obligatoire"),
    date: Yup.date().required("Ce champ est obligatoire"),
    file: Yup.mixed()
      .required("Veuillez selectionner un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE),
  });
  const validationSchemaUpdate = Yup.object().shape({
    titre: Yup.string().required("Ce champ est obligatoire"),
    date: Yup.date().required("Ce champ est obligatoire"),
  });

  const [initialValues, setInitialValues] = useState({
    titre: "",
    titreObj: { id: 0, name: "" },
    date: "",
    file: "",
    link_file: "",
  });
  const [initialValuesSchool, setInitialValuesSchool] = useState({
    titre: "",
    titreObj: { id: 0, name: "" },
    date: "",
    file: "",
    link_file: "",
  });
  const [initialValuesCarteIdentite, setInitialValuesCarteIdentite] = useState({
    piece_did_recto: "",
    piece_did_verso: "",
    num_carte: "",
    date_exp_carte: "",
    lieu_de_naissance: "",
    link_verso: "",
    link_recto: "",
  });
  const [initialValuesPasseport, setInitialValuesPasseport] = useState({
    num_passport: "",
    date_em_passport: "",
    date_exp_passport: "",
    file_passport: "",
    lieu_de_naissance: "",
    link_file: "",
  });
  const [initialValuesTitreSejour, setInitialValuesTitreSejour] = useState({
    sejour_recto: "",
    sejour_verso: "",
    lieu_de_naissance: "",
    link_verso: "",
    link_recto: "",
  });
  const [initialValuesRes, setInitialValuesRes] = useState({
    piece_res: "",
    lieu_de_naissance: "",
    link_file: "",
  });
  const [initialValuesRib, setInitialValuesRib] = useState({
    rib: userF?.profile?.rib ? userF?.profile?.rib : "",
  });
  const [initialValuesSecSocial, setInitialValuesSecSocial] = useState({
    type_sec_social: "",
    num_sec_social: "",
    justificatif_sec_social: "",
    link_file: "",
  });
  const [initialValuesJustifDom, setInitialValuesJustifDom] = useState({
    justificatif_domicile: "",
    file_link: "",
  });
  const handleChange = (e) => {
    setTypeID(e.target.value);
  };
  const [progressScolar, setProgressScolar] = useState(0);
  var configsScolar = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressScolar(percentCompleted);
    },
  };
  const createScolar = (values) => {
    //console.log(values.titre);
    Api.post(
      Urls.POST_SCOLARITE,
      {
        user_id: user?.user?.id,
        school_document_type_id: values.titre,
        obtained_date: values.date,
        file: values.file,
      },
      configsScolar
    )
      .then((res) => {
        //console.log(res);
        setProgressScolar(0);
        toast.success(res?.data?.message);
        setChange(!change);
        setOpen(false);
        setInitialValuesSchool({
          titre: "",
          titreObj: { id: 0, name: "" },
          date: "",
          file: "",
          link_file: "",
        });
      })
      .catch((err) => {
        //console.log(err);
        setProgressScolar(0);
        toast.error(err.response.data.message);
      });
  };
  const ModifyScolar = (values, id) => {
    //console.log(values.titre);
    Api.post(
      Urls?.UPDATE_SCOLARITER + id,
      {
        school_document_type_id: values.titre,
        obtained_date: values.date,
        ...(values.file !== "" && { file: values.file }),
      },
      configsScolar
    )
      .then((res) => {
        //console.log(res);
        setProgressScolar(0);
        toast.success(res?.data?.message);
        setIDScolar();
        setChange(!change);
        setOpen(false);
      })
      .catch((err) => {
        //console.log(err);
        setProgressScolar(0);
        setIDScolar();
        toast.error(err.response.data.message);
      });
  };
  //config for the identity card files
  const [progressIdentityCard, setProgressIdentitycard] = useState(0);
  var configscardIdentity = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressIdentitycard(percentCompleted);
    },
  };
  const IndentityAdd = (values) => {
    Api.post(
      Urls?.ADD_IDENTITY_CARD,
      {
        user_id: user?.user?.id,
        file_side1: values.piece_did_recto,
        file_side2: values.piece_did_verso,
        document_number: values.num_carte,
        expiration_date: values.date_exp_carte,
        place_of_birth: values.lieu_de_naissance,
      },
      configscardIdentity
    )
      .then((res) => {
        //console.log(res);

        setProgressIdentitycard(0);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
        setProgressIdentitycard(0);
      });
  };
  //config for the  passport add and files
  const [progressPasseport, setProgressPasseport] = useState(0);
  var configspasseport = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressPasseport(percentCompleted);
    },
  };
  const passeportAdd = (values) => {
    //console.log(typeID);
    Api.post(
      Urls?.ADD_PASSPORT,
      {
        user_id: user?.user?.id,
        obtained_date: values.date_em_passport,
        document_number: values.num_passport,
        expiration_date: values.date_exp_passport,
        place_of_birth: values.lieu_de_naissance,
        file: values.file_passport,
      },
      configspasseport
    )
      .then((res) => {
        //console.log(res);

        setProgressPasseport(0);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
        setProgressPasseport(0);
      });
  };
  //config for the  titre de sejour add and files
  const [progressTitreSejour, setProgressTitreSejour] = useState(0);
  var configsTitreSejour = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressTitreSejour(percentCompleted);
    },
  };
  const TitreSejourAdd = (values) => {
    //console.log(typeID);
    //console.log(values, "this is the values");
    Api.post(
      Urls?.ADD_TITRE_SEJOUR,
      {
        user_id: user?.user?.id,
        file_side1: values.sejour_recto,
        file_side2: values.sejour_verso,
        place_of_birth: values.lieu_de_naissance,
      },
      configsTitreSejour
    )
      .then((res) => {
        //console.log(res);

        setProgressTitreSejour(0);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
        setProgressTitreSejour(0);
      });
  };
  //config for the  titre de sejour add and files
  const [progressRec, setProgressRec] = useState(0);
  var configsRec = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressRec(percentCompleted);
    },
  };
  const recipAdd = (values) => {
    //console.log(typeID);
    //console.log(values, "this is the values");
    Api.post(
      Urls?.ADD_RES,
      {
        user_id: user?.user?.id,
        file: values.piece_res,
        place_of_birth: values.lieu_de_naissance,
      },
      configsRec
    )
      .then((res) => {
        //console.log(res);

        setProgressRec(0);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
        setProgressRec(0);
      });
  };
  //patch/post rib
  const patchRib = (values) => {
    Api.patch(
      Urls.MODIFY_INTERIMAIRE + user?.user?.id + "/interim",
      {
        rib: values.rib,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        //console.log(res);

        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
  const [typeSoc, setTypeSoc] = useState("social_security_certificate");
  const handleChangeSoc = (e) => {
    setTypeSoc(e.target.value);
  };
  //config for the  SEC SOCIAL and files
  const [progresssecS, setProgressSecS] = useState(0);
  var configsSecSocial = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressSecS(percentCompleted);
    },
  };
  const secSocialAdd = (values) => {
    //console.log(values, "this is the values");
    Api.post(
      Urls?.ADD_SECURITE_SOCIAL,
      {
        user_id: user?.user?.id,
        document_number: values.num_sec_social,
        file: values.justificatif_sec_social,
      },
      configsSecSocial
    )
      .then((res) => {
        //console.log(res);

        setProgressSecS(0);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
        setProgressSecS(0);
      });
  };
  //config for the  justificatif de domicile  add and files
  const [progresssDom, setProgressDom] = useState(0);
  var configsDom = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressDom(percentCompleted);
    },
  };
  const domAdd = (values) => {
    //console.log(typeID);
    //console.log(values, "this is the values");
    Api.post(
      Urls?.ADD_JUSTIF_DOM,
      {
        user_id: user?.user?.id,
        file: values.justificatif_domicile,
      },
      configsDom
    )
      .then((res) => {
        //console.log(res);

        setProgressDom(0);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
        setProgressDom(0);
      });
  };
  //rechercher des certificat pour le autocomplete
  const SearchCertif = (value) => {
    Api.get(Urls.SEARCH_CERTIFICATE, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        search: value,
      },
    })
      .then((res) => {
        //console.log(res);
        setCertification(res?.data?.certificate_document_types);
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  //create certificate
  const [progressCertificate, setProgressCertificate] = useState(0);
  var configscertificate = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressCertificate(percentCompleted);
    },
  };
  const createCertificate = (values) => {
    //console.log(values.titre);
    Api.post(
      Urls.POST_CERTIFICTAE,
      {
        user_id: user?.user?.id,
        certificate_document_type_id: values.titre,
        expiration_date: values.date,
        file: values.file,
      },
      configscertificate
    )
      .then((res) => {
        //console.log(res);
        setProgressCertificate(0);
        toast.success(res?.data?.message);
        setChange(!change);
        setInitialValues({
          titre: "",
          titreObj: { id: 0, name: "" },
          date: "",
          file: "",
          link_file: "",
        });
      })
      .catch((err) => {
        //console.log(err);
        setProgressCertificate(0);
        toast.error(err.response.data.message);
      });
  };

  const aceeptCand = () => {
    let epi_final = [];
    //console.log(epi_ids, "with etat epi");
    for (let index = 0; index < epi_ids.length; index++) {
      const element = epi_ids[index];
      if (element?.etat === true) epi_final.push(element?.id);
    }
    //console.log(epi_final, "final epis");
    Api.post(
      Urls.UPDATE_CANDIDATURE + id,
      {
        candidate_status: "ACCEPTED",
        epi: epi_final,
      },
      {
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${user?.access_token}`,
        },
      }
    )
      .then((res) => {
        //console.log(res);
        toast.success("Offre acceptée");
        setOpenAccepter(false);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  const ModifyCertificate = (values, id) => {
    //console.log(values.titre);
    Api.post(
      Urls?.UPDATE_CERTIFICATE + id,
      {
        certificate_document_type_id: values.titre,
        expiration_date: values.date,
        ...(values.file !== "" && { file: values.file }),
      },
      configscertificate
    )
      .then((res) => {
        //console.log(res);
        setProgressCertificate(0);
        toast.success(res?.data?.message);
        setIDCert();
        setChange(!change);
        setOpen(false);
      })
      .catch((err) => {
        //console.log(err);
        setProgressCertificate(0);
        setIDCert();
        toast.error(err.response.data.message);
      });
  };
  const executeSave = () => {
    if (!userF?.profile?.rib) {
      rib.current.submitForm();
    }
    if (!missing_documents?.national_identity_card && !missing_documents?.passport && !missing_documents?.residence_permit && !missing_documents?.residence_permit_receipt) {
      if (typeID === "identity_card") {
        identityCard?.current?.submitForm();
      }
      if (typeID === "passport") {
        passport?.current?.submitForm();
      }
      if (typeID === "residence_permit") {
        residencePermit?.current?.submitForm();
      }
      if (typeID === "residence_permit_receipt") {
        residenceReceipt?.current?.submitForm();
      }
    }
    if (!missing_documents?.vital_card && !missing_documents?.social_security_certificate) {
      secSocial?.current?.submitForm();
    }
    if (!missing_documents?.address_proof) {
      justifDom?.current?.submitForm();
    }
  };
  const [type, setType] = useState("Autre");
  const [typeID, setTypeID] = useState("identity_card");
  const [typeC, setTypeC] = useState(null);
  const [open, setOpen] = useState(false);
  const [idOffer, setIDOffer] = useState(null);
  const handleClickCandidature = (id, type) => {
    setIDOffer(id);
    setType(type);
    setOpen(true);
  };
  const [epi, setEpi] = useState([]);
  const [epi_ids, setEpi_ids] = useState([]);
  useEffect(() => {
    if (contrat) {
      Api.get(Urls.GET_DETAIL_CONTRAT + contrat?.id, {
        headers: { "content-type": "application/json", authorization: `Bearer ${user?.access_token}` },
      })
        .then((res) => {
          //console.log(res, "contrat detail");
          let ids = [];
          setEpi(res?.data?.contract?.equipment_to_be_provided);
          for (let index = 0; index < res?.data?.contract?.equipment_to_be_provided.length; index++) {
            ids.push({ id: res?.data?.contract?.equipment_to_be_provided[index].id, etat: false });
          }
          //console.log(ids, "ids of epi");
        })
        .catch((err) => {
          //console.log(err);
        });
    }
  }, []);
  return (
    <Box sx={{ ":hover": { filter: "brightness(0.95)", background: "white" }, cursor: "pointer" }}>
      {!loading && (
        <BoxContainer backgroundColor={color}>
          <Box display="flex" justifyContent="space-between" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" }, gap: { xs: "8px", sm: "0px", md: "0px", lg: "0px" }, alignItems: { xs: "start", sm: "center", md: "center", lg: "center" } }}>
            <Box onClick={() => handleClickCandidature(contrat ? contrat?.id : offreID, contrat ? "contrat" : "offre")} display="flex" flexDirection="column" flex={1}>
              <Box display="flex" gap="8px" alignItems="center">
                {matchingLevel !== "weak" && <MyChip variant="matching_parfait" label="💡 Offre suggérée" />}

                {contrat && <Box>{afficherDifferenceDateHeurs(contrat?.created_at) < 48 && <MyChip variant="skill" label={48 - afficherDifferenceDateHeurs(contrat?.created_at) + "h restantes"} />}</Box>}
              </Box>
              <Box>
                <Typography onClick={() => handleClickCandidature(contrat ? contrat?.id : offreID, contrat ? "contrat" : "offre")} sx={{ cursor: "pointer", fontSize: "18px", fontWeight: "500", lineHeight: "28.8px" }}>
                  {offreName}
                </Typography>
              </Box>
              {contrat ? (
                <Box marginBottom="20px">
                  <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22.4px", color: "#4A526C" }}>{afficherDifferenceDate(contrat?.created_at)}</Typography>
                </Box>
              ) : (
                <Box marginBottom="20px">
                  <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22.4px", color: "#4A526C" }}>
                    Candidaté le {moment(createdAt).format("DD MMMM YYYY")} à {createdAt?.split(" ")[1].split(":")[0]}h{createdAt?.split(" ")[1].split(":")[1]}
                  </Typography>
                </Box>
              )}
            </Box>
            <Box onClick={() => handleClickCandidature(contrat ? contrat?.id : offreID, contrat ? "contrat" : "offre")} flex={2} display="flex" justifyContent="center" sx={{ flexDirection: { xs: "column", sm: "column", lg: "row", md: "row" }, alignItems: { sm: "inherit", xs: "inherit", md: "center", lg: "center" } }} gap="8px">
              <Box>
                <Typography>💰{hourly_rate}€/h</Typography>
              </Box>
              <Box sx={{ display: { xs: "none", sm: "none", md: "inherit", lg: "inherit" } }}>
                <img src={iconPoint} alt="icon-point" />
              </Box>
              <Box>
                <Typography>📍{address}</Typography>
              </Box>
              <Box sx={{ display: { xs: "none", sm: "none", md: "inherit", lg: "inherit" } }}>
                <img src={iconPoint} alt="icon-point" />
              </Box>
              <Box display="flex" gap="8px">
                <Box>
                  <Typography>🗓 {moment(start_date).format("DD MMMM YYYY")}</Typography>
                </Box>
                <Box>
                  <img src={arrowRightIcon} alt="icon-point" />
                </Box>
                <Box>
                  <Typography>{moment(end_date).format("DD MMMM YYYY")}</Typography>
                </Box>
              </Box>
            </Box>
            <Box flex={1} display="flex" gap="10px" alignItems="center">
              {(etat === "APPLIED" || etat === "PENDING") && <MyChip icon={chipSelector(etat) === "En attente" ? AttenteIcon : null} variant="skill" label={chipSelector(etat)} />}

              {etat === "APPLICATION_CANCELED" && <MyChip icon={Xredicon} variant="rejected_offer" label={chipSelector(etat)} />}
              {etat === "OFFER_REJECTED" && <MyChip icon={Xredicon} variant="rejected_offer" label={chipSelector(etat)} />}
              {etat === "APPLICATION_REJECTED" && <MyChip icon={Xredicon} variant="rejected_offer" label={chipSelector(etat)} />}
              {etat === "WAITING_SIGNATURE" && <MyChip variant="skill" label={chipSelector(etat)} />}
              {etat === "CONTRACT_IN_CREATION" && <MyChip variant="skill" label={chipSelector(etat)} />}

              {possibleActions?.map((action) => (
                <Box key={action} display="flex" gap="8px" alignItems="center">
                  <Button
                    variant={actionSelector(action)}
                    onClick={() => {
                      if (actionTranslate[`${action}`] === "Annuler") {
                        setOpenAnnuler({
                          etat: true,
                          id: id,
                        });
                      }
                      if (actionTranslate[`${action}`] === "Accepter") {
                        if (!contrat?.equipment_to_be_provided) {
                          aceeptCand(id);
                        } else {
                          setOpenAccepter(true);
                        }
                      }
                      if (actionTranslate[`${action}`] === "Signer") {
                        //console.log("signer redirection");
                        setOpenDropContrat({ open: true, data: contrat });
                      }
                      if (actionTranslate[`${action}`] === "Refuser") {
                        setOpenRefuser(true);
                      }
                    }}
                  >
                    {actionTranslate[`${action}`]}
                  </Button>
                </Box>
              ))}
              {etat === "DOCUMENTS_PENDING_VALIDATION" && <MyChip onClick={() => setOpenDOCs(true)} variant="skill" label="En attente de documents" />}
              {etat === "WAITING_TO_SIGNE" && <MyChip variant="skill" label="Signée" />}
            </Box>
          </Box>
          <Modal
            open={openAnnuler?.etat && id === openAnnuler?.id}
            onClose={() =>
              setOpenAnnuler({
                etat: false,
                id: null,
              })
            }
          >
            <Box sx={modalStyle}>
              <BoxContainer>
                <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="text_xxl" fontWeight="700">
                    Annuler une candidature
                  </Typography>
                  <ButtonSquare
                    onClick={() =>
                      setOpenAnnuler({
                        etat: false,
                        id: null,
                      })
                    }
                    action="close"
                  />
                </Box>
                <Box display="flex" flexDirection="column" gap="24px">
                  <Box>
                    <Typography sx={{ fontSize: "14px", fontWeight: "600", lineHeight: "160%" }}>Êtes-vous sûr de bien vouloir Annuler cette candidature</Typography>
                  </Box>
                  <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                    <Button
                      onClick={() =>
                        setOpenAnnuler({
                          etat: false,
                          id: null,
                        })
                      }
                      variant="mj_secondary_md"
                    >
                      Annuler
                    </Button>
                    <Button onClick={() => AnnulerCandidature(id)} variant="mj_danger_md">
                      Confirmer
                    </Button>
                  </Box>
                </Box>
              </BoxContainer>
            </Box>
          </Modal>
          <Modal open={openRefuser} onClose={() => setOpenRefuser(false)}>
            <Box sx={modalStyle}>
              <BoxContainer>
                <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="text_xxl" fontWeight="700">
                    Refuser une Offre
                  </Typography>
                  <ButtonSquare onClick={() => setOpenRefuser(false)} action="close" />
                </Box>
                <Formik
                  initialValues={{
                    motif: "",
                    selected: "Autre",
                  }}
                  onSubmit={(values) => {
                    RejeterCandidature(id, values.selected, values.motif);
                  }}
                >
                  {({ values, errors, setFieldValue, touched }) => (
                    <Form>
                      <Box display="flex" flexDirection="column" gap="24px">
                        <Typography variant="text_normal" fontWeight="600" htmlFor="description">
                          Motif du refus
                        </Typography>
                        <FormControl>
                          <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={values.selected} onChange={(e) => setFieldValue("selected", e.target.value)}>
                            {motif?.map((mot) => (
                              <FormControlLabel key={mot?.id} value={mot?.id} control={<Radio checkedIcon={<CheckCircleRoundedIcon />} />} label={mot?.name} />
                            ))}
                            <FormControlLabel value="Autre" control={<Radio checkedIcon={<CheckCircleRoundedIcon />} />} label="Autre" />
                          </RadioGroup>
                        </FormControl>
                        {values.selected === "Autre" && (
                          <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
                            <Box sx={{ marginBottom: "6px" }}>
                              <Typography variant="text_normal" fontWeight="600" htmlFor="description" name="description">
                                Plus de détails
                              </Typography>

                              <Typography variant="text_normal" fontWeight="600" htmlFor="description" name="description" style={{ color: colors.main }}>
                                *
                              </Typography>
                            </Box>
                            <Field name="motif" type="input" placeholder="Veuillez donner plus de détails..." id="description" as={MyTextField} multiline fullWidth columns={5} rows={4} autoComplete="off" />
                            <ErrorMessage component={FormikErrorText} name="description" />
                          </Box>
                        )}
                        <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                          <Button onClick={() => setOpenRefuser(false)} variant="mj_secondary_md">
                            Annuler
                          </Button>
                          <Button disabled={values.selected === "Autre" && values.motif === "" ? true : false} type="submit" variant="mj_danger_md">
                            Confirmer
                          </Button>
                        </Box>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </BoxContainer>
            </Box>
          </Modal>
          <Modal open={open} onClose={() => setOpen(false)}>
            <Box sx={modalStyle2}>
              <BoxContainer>
                <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
                  <Box display="flex" gap="10px" alignItems="center">
                    <ButtonSquare onClick={() => setOpen(false)} action="close" />

                    <Typography variant="text_xxl" fontWeight="700">
                      Offre {contrat ? "finale" : ""} #{contrat ? contrat?.id : offreID}
                    </Typography>
                  </Box>
                  <Box display="flex" gap="8px" alignItems="center">
                    {(etat === "APPLIED" || etat === "PENDING") && <MyChip icon={chipSelector(etat) === "En attente" ? AttenteIcon : null} variant="skill" label={chipSelector(etat)} />}

                    {etat === "APPLICATION_CANCELED" && <MyChip icon={Xredicon} variant="rejected_offer" label={chipSelector(etat)} />}
                    {etat === "OFFER_REJECTED" && <MyChip icon={Xredicon} variant="rejected_offer" label={chipSelector(etat)} />}
                    {etat === "APPLICATION_REJECTED" && <MyChip icon={Xredicon} variant="rejected_offer" label={chipSelector(etat)} />}
                    {etat === "WAITING_SIGNATURE" && <MyChip variant="skill" label={chipSelector(etat)} />}
                    {etat === "CONTRACT_IN_CREATION" && <MyChip variant="skill" label={chipSelector(etat)} />}
                    {possibleActions?.map((action) => (
                      <Box key={action} display="flex" gap="8px" alignItems="center">
                        <Button
                          variant={actionSelector(action)}
                          onClick={() => {
                            if (actionTranslate[`${action}`] === "Annuler") {
                              setOpenAnnuler({
                                etat: true,
                                id: id,
                              });
                            }
                            if (actionTranslate[`${action}`] === "Accepter") {
                              if (!contrat?.equipment_to_be_provided) {
                                aceeptCand(id);
                              } else {
                                setOpenAccepter(true);
                              }
                            }
                            if (actionTranslate[`${action}`] === "Signer") {
                              //console.log("signer redirection");
                              setOpenDropContrat({ open: true, data: contrat });
                            }
                            if (actionTranslate[`${action}`] === "Refuser") {
                              setOpenRefuser(true);
                            }
                          }}
                        >
                          {actionTranslate[`${action}`]}
                        </Button>
                      </Box>
                    ))}
                    {etat === "DOCUMENTS_PENDING_VALIDATION" && <MyChip onClick={() => setOpenDOCs(true)} variant="skill" label="En attente de documents" />}
                    {etat === "WAITING_TO_SIGNE" && <MyChip variant="skill" label="Signée" />}
                  </Box>
                </Box>
                <DetailOffreFinale id={contrat ? contrat?.id : offreID} filter="finalOffer" type={contrat ? "contrat" : "offre"} />
              </BoxContainer>
            </Box>
          </Modal>
          <Modal open={openAccepter} onClose={() => setOpenAccepter(false)}>
            <Box sx={modalStyle}>
              <BoxContainer>
                <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="text_xxl" fontWeight="700">
                    Accepter l’offre
                  </Typography>
                  <ButtonSquare onClick={() => setOpenAccepter(false)} action="close" />
                </Box>
                <Formik
                  initialValues={{
                    password: "",
                  }}
                  onSubmit={(values) => {
                    aceeptCand(id, epi_ids);
                  }}
                >
                  {({ values, errors, setFieldValue, touched }) => (
                    <Form>
                      <Box display="flex" flexDirection="column" gap="24px">
                        <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
                          <Box sx={{ marginBottom: "6px" }}>
                            <Typography variant="text_normal" fontWeight="600" htmlFor="description" name="description">
                              Veuillez cocher les EPI que vous ne possedez pas
                            </Typography>

                            <Typography variant="text_normal" fontWeight="600" htmlFor="description" name="description" style={{ color: colors.main }}>
                              *
                            </Typography>
                          </Box>
                          <FormControl>
                            <FormGroup>
                              {epi?.map((mot, index) => (
                                <FormControlLabel
                                  key={mot?.id}
                                  control={
                                    <Checkbox
                                      checked={epi_ids[index]?.etat}
                                      onChange={() => {
                                        setEpi_ids([...epi_ids.slice(0, index), { ...epi_ids[index], id: mot.id, etat: !epi_ids[index]?.etat }, ...epi_ids.slice(index + 1)]);
                                      }}
                                      checkedIcon={<CheckCircleRoundedIcon />}
                                    />
                                  }
                                  label={mot?.name}
                                />
                              ))}
                            </FormGroup>
                          </FormControl>
                        </Box>

                        <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                          <Button onClick={() => setOpenAccepter(false)} variant="mj_secondary_md">
                            Annuler
                          </Button>
                          <Button type="submit" variant="mj_green_md">
                            Accepter
                          </Button>
                        </Box>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </BoxContainer>
            </Box>
          </Modal>
          <Modal open={openDocs} onClose={() => setOpenDOCs(false)}>
            <Box sx={modalStyle}>
              <BoxContainer>
                <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="text_xxl" fontWeight="700">
                    Ajouter les documents manquants
                  </Typography>
                  <ButtonSquare onClick={() => setOpenDOCs(false)} action="close" />
                </Box>
                <Box gap="20px" display="flex" flexDirection="column">
                  <Box display="flex" gap="10px" alignItems="center">
                    <Typography sx={{ fontSize: "20px", lineHeight: "160%", fontWeight: "700" }}>Pièce d’identité</Typography>
                    {(missing_documents?.national_identity_card || missing_documents?.passport || missing_documents?.residence_permit || missing_documents?.residence_permit_receipt) && <MyChip variant="skill" label="En cours de traitement" />}
                  </Box>
                  {missing_documents?.national_identity_card === null && missing_documents?.passport === null && missing_documents?.residence_permit === null && missing_documents?.residence_permit_receipt === null && (
                    <>
                      <Box>
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Type
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <FormControl>
                          <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={typeID} onChange={(e) => handleChange(e)}>
                            <FormControlLabel value="identity_card" control={<Radio checkedIcon={<CheckCircleRoundedIcon />} />} label="Carte d’identité" />
                            <FormControlLabel value="passport" control={<Radio checkedIcon={<CheckCircleRoundedIcon />} />} label="Passeport" />
                            <FormControlLabel value="residence_permit" control={<Radio checkedIcon={<CheckCircleRoundedIcon />} />} label="Titre de séjour" />
                            <FormControlLabel value="residence_permit_receipt" control={<Radio checkedIcon={<CheckCircleRoundedIcon />} />} label="Récépissé du titre de séjour" />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      {typeID === "identity_card" && (
                        <>
                          <Formik
                            innerRef={identityCard}
                            initialValues={initialValuesCarteIdentite}
                            onSubmit={(values) => {
                              IndentityAdd(values);
                            }}
                            validationSchema={validationSchemaCarteIdent}
                          >
                            {({ values, errors, setFieldValue, touched }) => (
                              <Form>
                                <Box display="flex" flexDirection="column" gap="20px">
                                  <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                                    <Box width="100%">
                                      <Box marginBottom="6px">
                                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                          Recto
                                        </Typography>
                                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                          *
                                        </Typography>
                                      </Box>
                                      <Box width="100%" position="relative">
                                        <input
                                          style={{ opacity: "0", width: "100%", height: "100%", position: "absolute", zIndex: "99999999999" }}
                                          type="file"
                                          name="piece_did_recto"
                                          onChange={(e) => {
                                            const doc = e.target.files[0];
                                            setFieldValue("piece_did_recto", e.target.files[0]);
                                          }}
                                        />
                                        <MyTextField setFieldValue={setFieldValue} value={values?.piece_did_recto?.name} endAdornment="download" name="file" placeholder="Télécharger..." />
                                      </Box>
                                      <Box marginTop="2px" alignItems="center" display="flex" gap="7px">
                                        <Typography variant="text_clickable">Consulter le fichier</Typography>
                                        <img src={checkLinkicon} alt="link-icon" />
                                      </Box>
                                      {errors.piece_did_recto && touched.piece_did_recto ? (
                                        <Typography color="red" fontSize="12px">
                                          {errors.piece_did_recto}
                                        </Typography>
                                      ) : null}
                                    </Box>
                                    <Box width="100%">
                                      <Box marginBottom="6px">
                                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                          Verso
                                        </Typography>
                                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                          *
                                        </Typography>
                                      </Box>
                                      <Box width="100%" position="relative">
                                        <input
                                          style={{ opacity: "0", width: "100%", height: "100%", position: "absolute", zIndex: "99999999999" }}
                                          type="file"
                                          name="piece_did_verso"
                                          onChange={(e) => {
                                            const doc = e.target.files[0];
                                            setFieldValue("piece_did_verso", e.target.files[0]);
                                          }}
                                        />
                                        <MyTextField setFieldValue={setFieldValue} value={values?.piece_did_verso?.name} endAdornment="download" name="file" placeholder="Télécharger..." />
                                      </Box>
                                      <Box marginTop="2px" alignItems="center" display="flex" gap="7px">
                                        <Typography variant="text_clickable">Consulter le fichier</Typography>
                                        <img src={checkLinkicon} alt="link-icon" />
                                      </Box>
                                      {errors.piece_did_verso && touched.piece_did_verso ? (
                                        <Typography color="red" fontSize="12px">
                                          {errors.piece_did_verso}
                                        </Typography>
                                      ) : null}
                                    </Box>
                                  </Box>
                                  <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                                    <Box width="100%">
                                      <Box marginBottom="6px">
                                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                          Numéro de la carte
                                        </Typography>
                                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                          *
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <MyTextField setFieldValue={setFieldValue} value={values.num_carte} name="num_carte" placeholder="Numéro de la carte..." />
                                        {errors.num_carte && touched.num_carte ? (
                                          <Typography color="red" fontSize="12px">
                                            {errors.num_carte}
                                          </Typography>
                                        ) : null}
                                      </Box>
                                    </Box>
                                    <Box width="100%">
                                      <Box marginBottom="6px">
                                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                          Date d’expiration
                                        </Typography>
                                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                          *
                                        </Typography>
                                      </Box>
                                      <Field value={values.date_exp_carte} setFieldValue={setFieldValue} name="date_exp_carte" placeholder="Date..." as={MyDPFullDate}></Field>
                                      {errors.date_exp_carte && touched.date_exp_carte ? (
                                        <Typography color="red" fontSize="12px">
                                          {errors?.date_exp_carte}
                                        </Typography>
                                      ) : null}
                                    </Box>
                                  </Box>
                                  <Box>
                                    <Box marginBottom="6px">
                                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                        Lieu de naissance
                                      </Typography>
                                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                        *
                                      </Typography>
                                    </Box>
                                    <MyTextField setFieldValue={setFieldValue} value={values.lieu_de_naissance} name="lieu_de_naissance" placeholder="Lieu de naissance..." />
                                    {errors.lieu_de_naissance && touched.lieu_de_naissance ? (
                                      <Typography color="red" fontSize="12px">
                                        {errors.lieu_de_naissance}
                                      </Typography>
                                    ) : null}
                                  </Box>
                                  <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                                    {progressIdentityCard !== 0 && (
                                      <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
                                        <CircularProgress />
                                        <Typography fontSize="14px" fontWeight="600" color={colors.main}>
                                          {progressIdentityCard}%
                                        </Typography>
                                      </Box>
                                    )}
                                  </Box>
                                </Box>
                              </Form>
                            )}
                          </Formik>
                        </>
                      )}
                      {typeID === "passport" && (
                        <>
                          <Formik
                            innerRef={passport}
                            initialValues={initialValuesPasseport}
                            onSubmit={(values) => {
                              passeportAdd(values);
                            }}
                            validationSchema={validationSchemaPasseport}
                          >
                            {({ values, errors, setFieldValue, touched }) => (
                              <Form>
                                <Box display="flex" flexDirection="column" gap="20px">
                                  <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                                    <Box width="100%">
                                      <Box marginBottom="6px">
                                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                          Numéro du passeport
                                        </Typography>
                                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                          *
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <MyTextField setFieldValue={setFieldValue} value={values?.num_passport} name="num_passport" placeholder=" Numéro du passeport..." />
                                        {errors.num_passport && touched.num_passport ? (
                                          <Typography color="red" fontSize="12px">
                                            {errors.num_passport}
                                          </Typography>
                                        ) : null}
                                      </Box>
                                    </Box>
                                    <Box width="100%">
                                      <Box marginBottom="6px">
                                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                          Date d’émission
                                        </Typography>
                                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                          *
                                        </Typography>
                                      </Box>
                                      <Field maxDate={values.date_exp_passport} value={values.date_em_passport} setFieldValue={setFieldValue} name="date_em_passport" placeholder="Date..." as={MyDPFullDate}></Field>
                                      {errors.date_em_passport && touched.date_em_passport ? (
                                        <Typography color="red" fontSize="12px">
                                          {errors.date_em_passport}
                                        </Typography>
                                      ) : null}
                                    </Box>
                                  </Box>
                                  <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                                    <Box width="100%">
                                      <Box marginBottom="6px">
                                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                          Date d’expiration
                                        </Typography>
                                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                          *
                                        </Typography>
                                      </Box>
                                      <Field value={values.date_exp_passport} setFieldValue={setFieldValue} name="date_exp_passport" placeholder="Date..." minDate={values.date_em_passport} as={MyDPFullDate}></Field>
                                      {errors.date_exp_passport && touched.date_exp_passport ? (
                                        <Typography color="red" fontSize="12px">
                                          {errors.date_exp_passport}
                                        </Typography>
                                      ) : null}
                                    </Box>
                                    <Box width="100%">
                                      <Box marginBottom="6px">
                                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                          Pièce jointe
                                        </Typography>
                                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                          *
                                        </Typography>
                                      </Box>
                                      <Box width="100%" position="relative">
                                        <input
                                          style={{ opacity: "0", width: "100%", height: "100%", position: "absolute", zIndex: "99999999999" }}
                                          type="file"
                                          name="file_passport"
                                          onChange={(e) => {
                                            const doc = e.target.files[0];
                                            setFieldValue("file_passport", e.target.files[0]);
                                          }}
                                        />
                                        <MyTextField setFieldValue={setFieldValue} value={values?.file_passport?.name} endAdornment="download" name="file" placeholder="Télécharger..." />
                                      </Box>
                                      <Box marginTop="2px" alignItems="center" display="flex" gap="7px">
                                        <Typography variant="text_clickable">Consulter le fichier</Typography>
                                        <img src={checkLinkicon} alt="link-icon" />
                                      </Box>
                                      {errors.file_passport && touched.file_passport ? (
                                        <Typography color="red" fontSize="12px">
                                          {errors.file_passport}
                                        </Typography>
                                      ) : null}
                                    </Box>
                                  </Box>
                                  <Box>
                                    <Box marginBottom="6px">
                                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                        Lieu de naissance
                                      </Typography>
                                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                        *
                                      </Typography>
                                    </Box>
                                    <MyTextField setFieldValue={setFieldValue} value={values.lieu_de_naissance} name="lieu_de_naissance" placeholder="Lieu de naissance..." />
                                    {errors.lieu_de_naissance && touched.lieu_de_naissance ? (
                                      <Typography color="red" fontSize="12px">
                                        {errors.lieu_de_naissance}
                                      </Typography>
                                    ) : null}
                                  </Box>
                                  <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                                    {progressPasseport !== 0 && (
                                      <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
                                        <CircularProgress />
                                        <Typography fontSize="14px" fontWeight="600" color={colors.main}>
                                          {progressPasseport}%
                                        </Typography>
                                      </Box>
                                    )}
                                  </Box>
                                </Box>
                              </Form>
                            )}
                          </Formik>
                        </>
                      )}
                      {typeID === "residence_permit" && (
                        <>
                          <Formik
                            innerRef={residencePermit}
                            initialValues={initialValuesTitreSejour}
                            onSubmit={(values) => {
                              TitreSejourAdd(values);
                            }}
                            validationSchema={validationSchemaTitreSejour}
                          >
                            {({ values, errors, setFieldValue, touched }) => (
                              <Form>
                                <Box display="flex" flexDirection="column" gap="20px">
                                  <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                                    <Box width="100%">
                                      <Box marginBottom="6px">
                                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                          Recto
                                        </Typography>
                                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                          *
                                        </Typography>
                                      </Box>
                                      <Box width="100%" position="relative">
                                        <input
                                          style={{ opacity: "0", width: "100%", height: "100%", position: "absolute", zIndex: "99999999999" }}
                                          type="file"
                                          name="sejour_recto"
                                          onChange={(e) => {
                                            const doc = e.target.files[0];
                                            setFieldValue("sejour_recto", e.target.files[0]);
                                          }}
                                        />
                                        <MyTextField setFieldValue={setFieldValue} value={values?.sejour_recto?.name} endAdornment="download" name="file" placeholder="Télécharger..." />
                                      </Box>
                                      <Box marginTop="2px" alignItems="center" display="flex" gap="7px">
                                        <Typography variant="text_clickable">Consulter le fichier</Typography>
                                        <img src={checkLinkicon} alt="link-icon" />
                                      </Box>
                                      {errors.sejour_recto && touched.sejour_recto ? (
                                        <Typography color="red" fontSize="12px">
                                          {errors.sejour_recto}
                                        </Typography>
                                      ) : null}
                                    </Box>
                                    <Box width="100%">
                                      <Box marginBottom="6px">
                                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                          Verso
                                        </Typography>
                                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                          *
                                        </Typography>
                                      </Box>
                                      <Box width="100%" position="relative">
                                        <input
                                          style={{ opacity: "0", width: "100%", height: "100%", position: "absolute", zIndex: "99999999999" }}
                                          type="file"
                                          name="sejour_verso"
                                          onChange={(e) => {
                                            const doc = e.target.files[0];
                                            setFieldValue("sejour_verso", e.target.files[0]);
                                          }}
                                        />
                                        <MyTextField setFieldValue={setFieldValue} value={values?.sejour_verso?.name} endAdornment="download" name="file" placeholder="Télécharger..." />
                                      </Box>
                                      <Box marginTop="2px" alignItems="center" display="flex" gap="7px">
                                        <Typography variant="text_clickable">Consulter le fichier</Typography>
                                        <img src={checkLinkicon} alt="link-icon" />
                                      </Box>
                                      {errors.sejour_verso && touched.sejour_verso ? (
                                        <Typography color="red" fontSize="12px">
                                          {errors.sejour_verso}
                                        </Typography>
                                      ) : null}
                                    </Box>
                                  </Box>
                                  <Box>
                                    <Box marginBottom="6px">
                                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                        Lieu de naissance
                                      </Typography>
                                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                        *
                                      </Typography>
                                    </Box>
                                    <MyTextField setFieldValue={setFieldValue} value={values.lieu_de_naissance} name="lieu_de_naissance" placeholder="Lieu de naissance..." />
                                    {errors.lieu_de_naissance && touched.lieu_de_naissance ? (
                                      <Typography color="red" fontSize="12px">
                                        {errors.lieu_de_naissance}
                                      </Typography>
                                    ) : null}
                                  </Box>
                                  <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                                    {progressTitreSejour !== 0 && (
                                      <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
                                        <CircularProgress />
                                        <Typography fontSize="14px" fontWeight="600" color={colors.main}>
                                          {progressTitreSejour}%
                                        </Typography>
                                      </Box>
                                    )}
                                  </Box>
                                </Box>
                              </Form>
                            )}
                          </Formik>
                        </>
                      )}
                      {typeID === "residence_permit_receipt" && (
                        <>
                          <Formik
                            innerRef={residenceReceipt}
                            initialValues={initialValuesRes}
                            onSubmit={(values) => {
                              recipAdd(values);
                            }}
                            validationSchema={validationSchemaRes}
                          >
                            {({ values, errors, setFieldValue, touched }) => (
                              <Box display="flex" flexDirection="column" gap="20px">
                                <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                                  <Box width="100%">
                                    <Box marginBottom="6px">
                                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                        Pièce jointe
                                      </Typography>
                                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                        *
                                      </Typography>
                                    </Box>
                                    <Box width="100%" position="relative">
                                      <input
                                        style={{ opacity: "0", width: "100%", height: "100%", position: "absolute", zIndex: "99999999999" }}
                                        type="file"
                                        name="piece_res"
                                        onChange={(e) => {
                                          const doc = e.target.files[0];
                                          setFieldValue("piece_res", e.target.files[0]);
                                        }}
                                      />
                                      <MyTextField setFieldValue={setFieldValue} value={values?.piece_res?.name} endAdornment="download" name="file" placeholder="Télécharger..." />
                                    </Box>
                                    <Box marginTop="2px" alignItems="center" display="flex" gap="7px">
                                      <Typography variant="text_clickable">Consulter le fichier</Typography>
                                      <img src={checkLinkicon} alt="link-icon" />
                                    </Box>
                                    {errors.piece_res && touched.piece_res ? (
                                      <Typography color="red" fontSize="12px">
                                        {errors.piece_res}
                                      </Typography>
                                    ) : null}
                                  </Box>
                                  <Box width="100%" sx={{ display: { xs: "none", lg: "inherit", md: "inherit", sm: "inherit" } }}></Box>
                                </Box>
                                <Box>
                                  <Box marginBottom="6px">
                                    <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                      Lieu de naissance
                                    </Typography>
                                    <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                      *
                                    </Typography>
                                  </Box>
                                  <MyTextField setFieldValue={setFieldValue} value={values.lieu_de_naissance} name="lieu_de_naissance" placeholder="Lieu de naissance..." />
                                  {errors.lieu_de_naissance && touched.lieu_de_naissance ? (
                                    <Typography color="red" fontSize="12px">
                                      {errors.lieu_de_naissance}
                                    </Typography>
                                  ) : null}
                                </Box>
                                <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                                  {progressRec !== 0 && (
                                    <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
                                      <CircularProgress />
                                      <Typography fontSize="14px" fontWeight="600" color={colors.main}>
                                        {progressRec}%
                                      </Typography>
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                            )}
                          </Formik>
                        </>
                      )}
                    </>
                  )}
                  <Divider sx={{ margin: "10px 0px" }} />
                  <Box display="flex" gap="10px" alignItems="center">
                    <Typography sx={{ fontSize: "20px", lineHeight: "160%", fontWeight: "700" }}>Relevé d'identité bancaire</Typography>
                    {userF?.profile?.rib && <MyChip variant="skill" label="En cours de traitement" />}
                  </Box>
                  {!userF?.profile?.rib && (
                    <Formik
                      innerRef={rib}
                      initialValues={initialValuesRib}
                      onSubmit={(values) => {
                        patchRib(values);
                      }}
                      validationSchema={validationSchemaRIb}
                    >
                      {({ values, errors, setFieldValue, touched }) => (
                        <Form>
                          <Box display="flex" flexDirection="column" gap="20px">
                            <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                              <Box width="100%">
                                <Box marginBottom="6px">
                                  <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                    RIB
                                  </Typography>
                                  <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                    *
                                  </Typography>
                                </Box>
                                <Box width="100%">
                                  <MyTextField setFieldValue={setFieldValue} value={values?.rib} name="rib" placeholder="RIB..." />
                                  {errors.rib ? (
                                    <Typography color="red" fontSize="12px">
                                      {errors.rib}
                                    </Typography>
                                  ) : null}
                                </Box>
                              </Box>
                              <Box width="100%" sx={{ display: { xs: "none", lg: "inherit", md: "inherit", sm: "inherit" } }}></Box>
                            </Box>
                          </Box>
                        </Form>
                      )}
                    </Formik>
                  )}
                  <Divider sx={{ margin: "10px 0px" }} />
                  <Box display="flex" alignItems="center" gap="10px">
                    <Typography sx={{ fontSize: "20px", lineHeight: "160%", fontWeight: "700" }}>Sécurité sociale</Typography>
                    {(missing_documents?.social_security_certificate || missing_documents?.vital_card) && <MyChip variant="skill" label="En cours de traitement" />}
                  </Box>
                  {missing_documents?.social_security_certificate === null && missing_documents?.vital_card === null && (
                    <Formik
                      innerRef={secSocial}
                      initialValues={initialValuesSecSocial}
                      onSubmit={(values) => {
                        secSocialAdd(values);
                      }}
                      validationSchema={validationSchemaSecuSocial}
                    >
                      {({ values, errors, setFieldValue, touched }) => (
                        <Form>
                          <Box display="flex" flexDirection="column" gap="20px">
                            <Box>
                              <Box marginBottom="6px">
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                  Type
                                </Typography>
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                  *
                                </Typography>
                              </Box>
                              <FormControl>
                                <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={typeSoc} onChange={(e) => handleChangeSoc(e)}>
                                  <FormControlLabel value="vital_card" control={<Radio checkedIcon={<CheckCircleRoundedIcon />} />} label="Carte vitale" />
                                  <FormControlLabel value="social_security_certificate" control={<Radio checkedIcon={<CheckCircleRoundedIcon />} />} label="Attestation de sécurité sociale" />
                                </RadioGroup>
                              </FormControl>
                            </Box>

                            <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "column", md: "column", lg: "row" } }}>
                              <Box width="100%">
                                <Box marginBottom="6px">
                                  <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                    Numéro de sécurité sociale
                                  </Typography>
                                  <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                    *
                                  </Typography>
                                </Box>
                                <Box>
                                  <MyTextField setFieldValue={setFieldValue} value={values?.num_sec_social} name="num_sec_social" placeholder="Numéro de sécurité sociale..." />
                                  {errors.num_sec_social && touched.num_sec_social ? (
                                    <Typography color="red" fontSize="12px">
                                      {errors.num_sec_social}
                                    </Typography>
                                  ) : null}
                                </Box>
                              </Box>
                              <Box width="100%">
                                <Box marginBottom="6px">
                                  <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                    Pièce jointe
                                  </Typography>
                                  <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                    *
                                  </Typography>
                                </Box>
                                <Box width="100%" position="relative">
                                  <input
                                    style={{ opacity: "0", width: "100%", height: "100%", position: "absolute", zIndex: "99999999999" }}
                                    type="file"
                                    name="justificatif_sec_social"
                                    onChange={(e) => {
                                      const doc = e.target.files[0];
                                      setFieldValue("justificatif_sec_social", e.target.files[0]);
                                    }}
                                  />
                                  <MyTextField setFieldValue={setFieldValue} value={values?.justificatif_sec_social?.name} endAdornment="download" name="file" placeholder="Télécharger..." />
                                </Box>
                                <Box marginTop="2px" alignItems="center" display="flex" gap="7px">
                                  <Typography variant="text_clickable">Consulter le fichier</Typography>
                                  <img src={checkLinkicon} alt="link-icon" />
                                </Box>
                                {errors.justificatif_sec_social && touched.justificatif_sec_social ? (
                                  <Typography color="red" fontSize="12px">
                                    {errors.justificatif_sec_social}
                                  </Typography>
                                ) : null}
                              </Box>
                            </Box>
                            <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                              {progresssecS !== 0 && (
                                <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
                                  <CircularProgress />
                                  <Typography fontSize="14px" fontWeight="600" color={colors.main}>
                                    {progresssecS}%
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Form>
                      )}
                    </Formik>
                  )}
                  <Divider sx={{ margin: "10px 0px" }} />
                  <Box>
                    <Box display="flex" alignItems="center" gap="10px">
                      <Typography sx={{ fontSize: "20px", lineHeight: "160%", fontWeight: "700" }}>Justificatif de domicile</Typography>
                      {missing_documents?.address_proof && <MyChip variant="skill" label="En cours de traitement" />}
                    </Box>
                    <Typography sx={{ fontSize: "12px", fontWeight: "400", lineHeight: "160%", color: "#666D83" }}>Insérer un justificatif à votre nom et prénom qui date de moins de 1 an</Typography>
                  </Box>
                  {missing_documents?.address_proof === null && (
                    <Formik
                      innerRef={justifDom}
                      initialValues={initialValuesJustifDom}
                      onSubmit={(values) => {
                        domAdd(values);
                      }}
                      validationSchema={validationSchemaJustificatifDomicile}
                    >
                      {({ values, errors, setFieldValue, touched }) => (
                        <Form>
                          <Box display="flex" flexDirection="column" gap="20px">
                            <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                              <Box width="100%">
                                <Box marginBottom="6px">
                                  <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                    Pièce jointe
                                  </Typography>
                                  <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                    *
                                  </Typography>
                                </Box>
                                <Box width="100%" position="relative">
                                  <input
                                    style={{ opacity: "0", width: "100%", height: "100%", position: "absolute", zIndex: "99999999999" }}
                                    type="file"
                                    name="justificatif_domicile"
                                    onChange={(e) => {
                                      const doc = e.target.files[0];
                                      setFieldValue("justificatif_domicile", e.target.files[0]);
                                    }}
                                  />
                                  <MyTextField setFieldValue={setFieldValue} value={values?.justificatif_domicile?.name} endAdornment="download" name="file" placeholder="Télécharger..." />
                                </Box>
                                <Box marginTop="2px" alignItems="center" display="flex" gap="7px">
                                  <Typography variant="text_clickable">Consulter le fichier</Typography>
                                  <img src={checkLinkicon} alt="link-icon" />
                                </Box>
                                {errors.justificatif_domicile && touched.justificatif_domicile ? (
                                  <Typography color="red" fontSize="12px">
                                    {errors.justificatif_domicile}
                                  </Typography>
                                ) : null}
                              </Box>
                              <Box width="100%" sx={{ display: { xs: "none", lg: "inherit", md: "inherit", sm: "inherit" } }}></Box>
                            </Box>
                            <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                              {progresssDom !== 0 && (
                                <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
                                  <CircularProgress />
                                  <Typography fontSize="14px" fontWeight="600" color={colors.main}>
                                    {progresssDom}%
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Form>
                      )}
                    </Formik>
                  )}
                  <Divider sx={{ margin: "10px 0px" }} />
                  {miss?.certificate_document_types !== null ? (
                    <Formik
                      initialValues={initialValues}
                      onSubmit={(values) => {
                        createCertificate(values);
                      }}
                      validationSchema={validationSchema}
                    >
                      {({ values, errors, setFieldValue, touched }) => (
                        <Form>
                          <Box gap="20px" display="flex" flexDirection="column">
                            <Box display="flex" alignItems="center" gap="10px">
                              <Typography sx={{ fontSize: "20px", lineHeight: "160%", fontWeight: "700" }}>Certifications</Typography>
                            </Box>
                            <Box>
                              <Box marginBottom="6px">
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                  Titre
                                </Typography>
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                  *
                                </Typography>
                              </Box>
                              <Box>
                                <Autocomplete
                                  disablePortal
                                  ListboxProps={{ style: { maxHeight: "180px" } }}
                                  fullWidth
                                  id="combo-box-demo"
                                  value={values?.titreObj}
                                  onChange={(e, value) => {
                                    if (value) {
                                      setFieldValue("titre", value?.id);
                                      setFieldValue("titreObj", value);
                                    }
                                  }}
                                  options={missing_documents?.certificate_document_types ? missing_documents?.certificate_document_types : []}
                                  getOptionLabel={(Option) => Option.name}
                                  renderInput={(params) => (
                                    <TextField
                                      sx={{ maxWidth: "100%" }}
                                      value={values.titre}
                                      onChange={(e) => {
                                        //console.log("change");
                                      }}
                                      {...params}
                                      variant="outlined"
                                      InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <img src={RechercheIcon} alt="end" />
                                          </InputAdornment>
                                        ),
                                        endAdornment: null,
                                      }}
                                      placeholder="Certification..."
                                    />
                                  )}
                                />
                              </Box>
                              {errors.titre && touched.titre ? (
                                <Typography color="red" fontSize="12px">
                                  {errors.titre}
                                </Typography>
                              ) : null}
                            </Box>
                            <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                              <Box width="100%">
                                <Box marginBottom="6px">
                                  <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                    Date d’expiration
                                  </Typography>
                                  <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                    *
                                  </Typography>
                                </Box>
                                <Field value={values.date} setFieldValue={setFieldValue} name="date" placeholder="Date..." minDate={new Date()} as={MyDP}></Field>
                                {errors.date && touched.date ? (
                                  <Typography color="red" fontSize="12px">
                                    {errors.date}
                                  </Typography>
                                ) : null}
                              </Box>
                              <Box width="100%">
                                <Box marginBottom="6px">
                                  <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                    Pièce jointe
                                  </Typography>
                                </Box>
                                <Box width="100%" position="relative">
                                  <input
                                    style={{ opacity: "0", width: "100%", height: "100%", position: "absolute", zIndex: "99999999999" }}
                                    type="file"
                                    name="file"
                                    onChange={(e) => {
                                      const doc = e.target.files[0];
                                      setFieldValue("file", e.target.files[0]);
                                    }}
                                  />
                                  <MyTextField setFieldValue={setFieldValue} value={values?.file?.name} endAdornment="download" name="file" placeholder="Télécharger..." />
                                </Box>
                                <Box onClick={() => (values.link_file ? window.open(values.link_file, "_blank") : null)} marginTop="2px" alignItems="center" display="flex" gap="7px">
                                  <Typography variant="text_clickable">Consulter le fichier</Typography>
                                  <img src={checkLinkicon} alt="link-icon" />
                                </Box>
                                {errors.file && touched.file ? (
                                  <Typography color="red" fontSize="12px">
                                    {errors.file}
                                  </Typography>
                                ) : null}
                              </Box>
                            </Box>
                            <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                              <Button type="submit" variant="mj_primary_md">
                                Ajouter
                              </Button>
                            </Box>
                          </Box>
                        </Form>
                      )}
                    </Formik>
                  ) : null}
                  <Box marginBottom="20px" display="flex" justifyContent="space-between" alignItems="center">
                    <Typography sx={{ fontSize: "20px", lineHeight: "160%", fontWeight: "700" }}>Scolarité</Typography>
                  </Box>
                  <Formik
                    innerRef={formikRef}
                    initialValues={initialValuesSchool}
                    onSubmit={(values) => {
                      createScolar(values);
                    }}
                    validationSchema={validationSchema}
                  >
                    {({ values, errors, setFieldValue, touched }) => (
                      <Form>
                        <Box gap="20px" display="flex" flexDirection="column">
                          <Box>
                            <Box marginBottom="6px">
                              <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                Titre
                              </Typography>
                              <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                *
                              </Typography>
                            </Box>
                            <Box>
                              <Autocomplete
                                ListboxProps={{ style: { maxHeight: "180px" } }}
                                disablePortal
                                fullWidth
                                id="combo-box-demo"
                                value={values?.titreObj}
                                onChange={(e, value) => {
                                  if (value) {
                                    setFieldValue("titre", value?.id);
                                    setFieldValue("titreObj", value);
                                  }
                                }}
                                options={miss?.school_document_types ? miss?.school_document_types : []}
                                getOptionLabel={(Option) => Option.name}
                                renderInput={(params) => (
                                  <TextField
                                    sx={{ maxWidth: "100%" }}
                                    value={values.titre}
                                    onChange={(e) => {
                                      //console.log("change");
                                    }}
                                    {...params}
                                    variant="outlined"
                                    InputProps={{
                                      ...params.InputProps,
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <img src={RechercheIcon} alt="end" />
                                        </InputAdornment>
                                      ),
                                      endAdornment: null,
                                    }}
                                    placeholder="Scolarité..."
                                  />
                                )}
                              />
                            </Box>
                          </Box>
                          <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                            <Box width="100%">
                              <Box marginBottom="6px">
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                  Date de délivrance
                                </Typography>
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                  *
                                </Typography>
                              </Box>
                              <Field value={values.date} setFieldValue={setFieldValue} name="date" placeholder="Date..." /*maxDate={new Date()}*/ as={MyDP}></Field>
                              {errors.date && touched.date ? (
                                <Typography color="red" fontSize="12px">
                                  {errors.date}
                                </Typography>
                              ) : null}
                            </Box>
                            <Box width="100%">
                              <Box marginBottom="6px">
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                  Pièce jointe
                                </Typography>
                              </Box>
                              <Box width="100%" position="relative">
                                <input
                                  style={{ opacity: "0", width: "100%", height: "100%", position: "absolute", zIndex: "99999999999" }}
                                  type="file"
                                  name="file"
                                  onChange={(e) => {
                                    const doc = e.target.files[0];
                                    setFieldValue("file", e.target.files[0]);
                                    setFile(doc?.name);
                                  }}
                                />
                                <MyTextField setFieldValue={setFieldValue} value={file} endAdornment="download" name="file" placeholder="Télécharger..." error={errors.password && touched.password ? true : false} />
                              </Box>
                              <Box marginTop="2px" alignItems="center" display="flex" gap="7px">
                                <Typography variant="text_clickable">Consulter le fichier</Typography>
                                <img src={checkLinkicon} alt="link-icon" />
                              </Box>
                            </Box>
                          </Box>
                          <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                            <Button type="submit" variant="mj_primary_md">
                              Ajouter
                            </Button>
                          </Box>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                  <Box display="flex" alignItems="center" gap="8px" justifyContent="flex-end">
                    <Button variant="mj_secondary_md" onClick={() => setOpenDOCs(false)}>
                      Annuler
                    </Button>
                    <Button onClick={() => executeSave()} variant="mj_primary_md">
                      Enregistrer
                    </Button>
                  </Box>
                </Box>
              </BoxContainer>
            </Box>
          </Modal>
          <Modal open={openDropContrat?.open && contrat?.id === openDropContrat?.data?.id} onClose={() => setOpenDropContrat({ open: false, data: null })}>
            <Box sx={modalStyle}>
              <BoxContainer>
                <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="text_xxl" fontWeight="700">
                    Signer le contrat
                  </Typography>
                  <ButtonSquare onClick={() => setOpenDropContrat({ open: false, data: null })} action="close" />
                </Box>
                <Box display="flex" flexDirection="column" gap="16px">
                  <Box display="flex" flexDirection="column" gap="5px">
                    <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                      Contrat
                    </Typography>
                    <Typography variant="text_clickable">Contrat.pdf</Typography>
                  </Box>
                  <Box>
                    <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                      Contrat signé
                    </Typography>

                    <Box sx={{ cursor: "pointer", minWidth: "200px" }} position="relative">
                      <input onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop} accept="application/pdf" onChange={(e) => VerifyFile(e)} style={{ opacity: "0", zIndex: "0", height: "100%", width: "100%", position: "absolute" }} id="avatar-input" type="file" />
                      <Box justifyContent="center" display="flex">
                        <Box sx={{ width: "100%", transition: "0.2s", background: `${drag ? "rgba(75, 70, 92, 0.1)" : "white"}` }} gap="10px" display="flex" flexDirection="column" alignItems="center" padding="40px 50px" border="1px dashed #DBDADE">
                          <img src={uploadIcon} alt="upload" />
                          <Typography textAlign="center" fontSize="22px" lineHeight="30px" fontWeight="500" color="#4B465C">
                            {!file && "Déposez le fichier ici ou cliquez pour télécharger"}
                            {file && file.name}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box display="flex" alignItems="center" gap="8px" justifyContent="flex-end">
                    <Button variant="mj_secondary_md" onClick={() => setOpenDropContrat({ open: false, data: null })}>
                      Annuler
                    </Button>
                    <Button onClick={() => setOpenDropContrat({ open: false, data: null })} variant="mj_primary_md">
                      Enregistrer
                    </Button>
                  </Box>
                </Box>
              </BoxContainer>
            </Box>
          </Modal>
        </BoxContainer>
      )}
    </Box>
  );
}
export default CandidatureComponent;
