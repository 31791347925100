import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Container, Divider, Typography } from "@mui/material";
import EmbaucherComponent from "../../../components/ui/EmbaucherComponent";
import { useAuthContext } from "../../../hooks/useAuthContext";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import leftArrowIcon from "../../../imgs/imgV2/leftArrowBlack.svg";
import arrowRightIcon from "../../../imgs/imgV2/arrowRightBlavk.svg";
function Embaucher({ id }) {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [change, setChange] = useState(false);
  const [users, setUsers] = useState([]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  //handle Pagination
  const handlePagination = (link) => {
    //console.log("getting data");
    Api.get(`${link}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        //console.log("RESPONES GHERBI", res);
        setData(res?.data);
        setUsers(res.data?.users);
        //console.log(res?.data?.meta?.links, "link pag");
      })
      .catch((err) => {
        //console.log(err);
        setUsers([]);
      });
  };
  useEffect(() => {
    Api.get(Urls.GET_EMBAUCHER + id + "/interims", {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        //console.log(res);
        setUsers(res?.data?.users);
        setData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        //console.log(err);
        setLoading(false);
      });
  }, [change]);
  return (
    <>
      {!loading ? (
        <Box>
          <Container maxWidth="lg">
            {users?.map((user) => (
              <EmbaucherComponent idOffer={id} userF={user} setChange={setChange} change={change} />
            ))}
          </Container>
          {users?.length > 0 && <Divider sx={{ margin: "24px 0px" }} />}
          {users?.length === 0 && <Typography textAlign="center">Aucun résultat</Typography>}
          {users?.length > 0 && (
            <Box marginBottom="24px" display="flex" alignItems="center" gap="20px" justifyContent="space-between">
              <Box flex={1}>
                {data?.links?.prev && (
                  <>
                    <Button
                      sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                      onClick={() => {
                        handlePagination(data?.links?.prev);
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      variant="mj_secondary_md"
                    >
                      <img style={{ marginRight: "8px" }} src={leftArrowIcon} alt="arrow-left" /> Précédent
                    </Button>
                    <Button
                      sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                      onClick={() => {
                        handlePagination(data?.links?.prev);

                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      variant="mj_secondary_md"
                    >
                      <img src={leftArrowIcon} alt="arrow-left" />
                    </Button>
                  </>
                )}
              </Box>
              <Box sx={{ flex: { xs: 8, sm: 8, md: 6, lg: 6 } }} display="flex" gap="10px" justifyContent="center">
                {data?.meta?.links?.map((link, index) => (
                  <>
                    {index !== 0 && index !== data?.meta?.last_page + 1 && (
                      <ButtonSquare
                        onClick={() => {
                          handlePagination(link.url);
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                        }}
                        action={link?.active ? "number-active" : "number"}
                        number={link.label}
                      />
                    )}
                  </>
                ))}
              </Box>
              <Box flex={1}>
                {data?.links?.next && (
                  <>
                    <Button
                      sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                      onClick={() => {
                        handlePagination(data?.links?.next);
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      variant="mj_secondary_md"
                    >
                      Suivant <img style={{ marginLeft: "8px" }} src={arrowRightIcon} alt="arrow-right" />
                    </Button>
                    <Button
                      sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                      onClick={() => {
                        handlePagination(data?.links?.next);
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      variant="mj_secondary_md"
                    >
                      <img src={arrowRightIcon} alt="arrow-right" />
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          )}
        </Box>
      ) : (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
    </>
  );
}

export default Embaucher;
