import { Box, Typography, Avatar, Button, IconButton, TextField, Modal, InputAdornment } from "@mui/material";
import React from "react";
import plusIconBlue from "../../../../imgs/imgV2/plusIconBlue.svg";
import docGray from "../../../../imgs/imgV2/DocGray.svg";
import PersonIcon from "@mui/icons-material/Person";

import Api from "../../../../api/Api";
import { Urls } from "../../../../api/Urls";
import { useEffect } from "react";
import { useState } from "react";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import plusIconBleu from "../../../../imgs/imgV2/plusIconBlue.svg";

import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import dollarIcon from "../../../../imgs/imgV2/dollarIcon.svg";

import { colors } from "../../../../theme/Colors";
import { Field, Form, Formik } from "formik";
import ButtonSquare from "../../../../components/ui/ButtonSquare";
import BoxContainer from "../../../../components/ui/BoxContainer";
import { modalStyle, modalStyle2 } from "../../../../theme/ModalStyle";
import MyChip from "../../../../components/ui/MyChip";
import moment from "moment";
import uploadIcon from "../../../../imgs/imgV2/upload-icon.svg";
import DetailsListeContrat from "../DetailsListeContrat";

function ContratsASignerSection() {
  const { user } = useAuthContext();
  const access_token = user?.access_token;

  const [contrats, setContrats] = useState([]);
  const [data, setData] = useState({});
  const [openSigner, setOpenSigner] = useState(false);
  const [idContrat, setIdContrat] = useState();
  const [showPasswordDel, setShowPasswordDel] = useState(false);
  const [date, setDate] = useState();
  const [change, setChange] = useState(false);
  const [loading, setLoading] = useState(true);
  const maxSize = 5 * 1024 * 1024;
  const [isFileVerified, setIsFileVerified] = useState(false);
  const [error, setError] = useState("");
  const [openDropContrat, setOpenDropContrat] = useState({ open: false, data: null });
  const [file, setFile] = useState(null);
  const [drag, setDrag] = useState(false);
  const handleDrop = () => {
    setDrag(false);
  };
  const handleDragOver = () => {
    setDrag(true);
  };
  const handleDragLeave = () => {
    setDrag(false);
  };
  const VerifyFile = (e) => {
    if (e.target?.files[0]) {
      if (!e.target?.files[0].size <= maxSize) {
        setError("File size > 5MB");
        setIsFileVerified(false);
      }
      if (e.target?.files[0].size <= maxSize) {
        setError("");
        setIsFileVerified(true);
        setFile(e.target?.files[0]);
      }
    } else {
      setFile(null);
    }
  };
  useEffect(() => {
    Api.get(Urls.GET_CONTRAT_A_SIGNER + user?.user?.id + "/contracts-to-signe", {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        perPage: 5,
      },
    })
      .then((res) => {
        //console.log(res, "liste des contrats");
        setContrats(res?.data?.contracts);
        setData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        //console.log(err);
        setLoading(false);
      });
  }, [change]);
  //show password
  function handleClickShowPasswordDel() {
    setShowPasswordDel(!showPasswordDel);
  }
  function handleMouseDownPasswordDel(event) {
    event.preventDefault();
  }
  const AccepterCandidature = (id, password) => {
    Api.patch(
      Urls.SIGN_CONTRAT_ID + id + "/sign",
      {
        candidate_status: "SIGNED",
        password: password,
      },
      {
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${user?.access_token}`,
        },
      }
    )
      .then((res) => {
        //console.log(res);
        toast.success("Offre acceptée");
        setOpenSigner(false);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  const [open, setOpen] = useState(false);
  function afficherDifferenceDate(date) {
    const now = moment();
    const diffe = moment(date);
    //console.log(date);
    const minutes = now.diff(diffe, "minutes");
    const hours = now.diff(diffe, "hours");
    const jours = now.diff(diffe, "days");
    const semaines = now.diff(diffe, "weeks");
    const mois = now.diff(diffe, "months");
    if (minutes === 1) {
      return "Créé il y a 1 minute";
    } else if (minutes > 1 && minutes < 60) {
      return `Créé il y a ${minutes} minutes`;
    }
    if (hours === 1) {
      return "Créé il y a 1 heure";
    } else if (hours > 1 && hours < 24) {
      return `Créé il y a ${hours} heures`;
    }
    if (jours === 1) {
      return "Créé il y a 1 jour";
    } else if (jours > 1 && jours < 7) {
      return `Créé il y a ${jours} jours`;
    } else if (semaines === 1) {
      return "Créé il y a 1 semaine";
    } else if (semaines > 1 && semaines < 4) {
      return `Créé il y a ${semaines} semaines`;
    } else if (mois === 1) {
      return "Créé il y a 1 mois";
    } else {
      return `Créé il y a ${mois} mois`;
    }
  }
  return (
    <Box sx={{ border: "1px solid #DEDFE4", borderRadius: "6px" }}>
      {/* header */}
      <Box padding="24px" display="flex" flexDirection="row" gap="14px">
        <Typography display="flex" flexDirection="row" alignItems="center" fontSize="20px" fontWeight="700">
          📝 Contrats à signer
        </Typography>
      </Box>

      {/* entreprises */}
      <Box display="flex" flexDirection="column" padding="6px 0px">
        {contrats.map((item, index) => (
          <Box key={index} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" padding="10px 20px">
            {/* type contrat */}
            <Box
              onClick={() => {
                setOpen(true);
                setIdContrat(item);
              }}
              sx={{ cursor: "pointer" }}
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap="6px"
            >
              <Box>
                <img src={item?.contract_type === "INIT" ? docGray : item?.contract_type === "PROLONGATION" ? plusIconBleu : dollarIcon} alt="doc-icon" />
              </Box>
              <Box>
                <Typography fontWeight="600" color="#575E76" fontSize="14px">
                  {item.id}
                </Typography>
              </Box>
            </Box>

            {/* entreprise */}
            <Box
              onClick={() => {
                setOpen(true);
                setIdContrat(item);
              }}
              sx={{ cursor: "pointer" }}
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap="10px"
            >
              <Avatar sx={{ height: "32px", width: "32px", position: "relative" }}>{item?.enterprise?.url_logo ? <img src={item?.enterprise?.url_logo} alt="profile-pic" style={{ height: "32px", width: "32px", borderRadius: "50%" }} /> : <PersonIcon sx={{ fontSize: "25px" }} />}</Avatar>{" "}
              <Typography fontWeight="500" fontSize="14px">
                {item.enterprise.name}
              </Typography>
            </Box>

            {/* is declared (déclarer/ complet) */}
            <Box>
              <Button
                onClick={() => {
                  setIdContrat(item);
                }}
                variant={"mj_primary_md"}
              >
                Signer
              </Button>
            </Box>
          </Box>
        ))}
      </Box>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={modalStyle2}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" flexDirection="column">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" gap="10px" alignItems="center">
                  <ButtonSquare onClick={() => setOpen(false)} action="close" />
                  <Typography variant="text_xxl" fontWeight="700">
                    Contrat #{idContrat?.id}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" gap="14px" alignItems="center">
                {idContrat?.status === "CURRENT" && <MyChip variant="active_offer" label="En cours" />}
                {idContrat?.status === "CANCELED" && <MyChip variant="rejected_offer" label="Annulé" />}
                {idContrat?.status === "SUSPENDED" && <MyChip variant="rejected_offer" label="Suspendu" />}
                {idContrat?.status === "UPCOMING" && <MyChip variant="skill" label="À venir" />}
                {idContrat?.status === "CREATING" && <MyChip variant="skill" label="En cours de création" />}
                {idContrat?.status === "FINISHED" && <MyChip variant="ended_offer" label="Terminé" />}
                {idContrat?.status === "PENDING" && <MyChip variant="pending_contract" label="En attente" />}{" "}
                <Typography fontSize="12px" color="#4A526C" fontWeight="400" lineHeight="160%">
                  {afficherDifferenceDate(date)}
                </Typography>
              </Box>
              <Typography marginTop="10px" fontSize="12px" fontWeight="600" color="red">
                {idContrat?.suspended_at && "Suspendu le : " + moment(idContrat?.suspended_at).format("DD MMMM YYYY")}
              </Typography>{" "}
            </Box>
            <DetailsListeContrat setDate={setDate} id={idContrat?.id} />
          </BoxContainer>
        </Box>
      </Modal>
      <Modal open={openSigner} onClose={() => setOpenSigner(false)}>
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Accepter l’offre
              </Typography>
              <ButtonSquare onClick={() => setOpenSigner(false)} action="close" />
            </Box>
            <Formik
              initialValues={{
                password: "",
              }}
              onSubmit={(values) => {
                AccepterCandidature(idContrat?.id, values.password);
              }}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <Box display="flex" flexDirection="column" gap="24px">
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                      <Typography sx={{ fontSize: "25px", fontWeight: "700", lineHeight: "160%" }}>Signature</Typography>
                      <Typography fontSize="14px" fontWeight="400" lineHeight="160%">
                        En introduisant votre mot de passe, vous confirmez avoir lu et accepté le contrat #{idContrat?.id}
                      </Typography>
                      <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                        <Box width="100%">
                          <Box marginBottom="6px">
                            <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                              Mot de passe
                            </Typography>
                            <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                              *
                            </Typography>
                          </Box>
                          <Field name="password" id="password">
                            {({ field, form, meta }) => (
                              <TextField
                                {...field}
                                fullWidth
                                placeholder="Saisir votre mot de passe"
                                autoComplete="Off"
                                type={showPasswordDel ? "text" : "password"}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end" style={{ paddingRight: "12px" }}>
                                      <IconButton size="small" aria-label="toggle password visibility" onClick={handleClickShowPasswordDel} onMouseDown={handleMouseDownPasswordDel} edge="end">
                                        {showPasswordDel ? <Visibility fontSize="12px" htmlColor="#9499A8" /> : <VisibilityOff fontSize="12px" htmlColor="#9499A8" />}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          </Field>
                          {errors.password && touched.password ? (
                            <Typography color="red" fontSize="12px">
                              {errors.password}
                            </Typography>
                          ) : null}
                        </Box>
                      </Box>
                    </Box>
                    <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                      <Button onClick={() => setOpenSigner(false)} variant="mj_secondary_md">
                        Annuler
                      </Button>
                      <Button disabled={values?.password?.length > 7 ? false : true} type="submit" variant="mj_green_md">
                        Signer
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </BoxContainer>
        </Box>
      </Modal>
      <Modal open={openDropContrat?.open} onClose={() => setOpenDropContrat({ open: false, data: null })}>
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Signer le contrat
              </Typography>
              <ButtonSquare onClick={() => setOpenDropContrat({ open: false, data: null })} action="close" />
            </Box>
            <Box display="flex" flexDirection="column" gap="16px">
              <Box display="flex" flexDirection="column" gap="5px">
                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                  Contrat
                </Typography>
                <Typography variant="text_clickable">Contrat.pdf</Typography>
              </Box>
              <Box>
                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                  Contrat signé
                </Typography>

                <Box sx={{ cursor: "pointer", minWidth: "200px" }} position="relative">
                  <input onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop} accept="application/pdf" onChange={(e) => VerifyFile(e)} style={{ opacity: "0", zIndex: "0", height: "100%", width: "100%", position: "absolute" }} id="avatar-input" type="file" />
                  <Box justifyContent="center" display="flex">
                    <Box sx={{ width: "100%", transition: "0.2s", background: `${drag ? "rgba(75, 70, 92, 0.1)" : "white"}` }} gap="10px" display="flex" flexDirection="column" alignItems="center" padding="40px 50px" border="1px dashed #DBDADE">
                      <img src={uploadIcon} alt="upload" />
                      <Typography textAlign="center" fontSize="22px" lineHeight="30px" fontWeight="500" color="#4B465C">
                        {!file && "Déposez le fichier ici ou cliquez pour télécharger"}
                        {file && file.name}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" gap="8px" justifyContent="flex-end">
                <Button variant="mj_secondary_md" onClick={() => setOpenDropContrat({ open: false, data: null })}>
                  Annuler
                </Button>
                <Button onClick={() => setOpenDropContrat({ open: false, data: null })} variant="mj_primary_md">
                  Enregistrer
                </Button>
              </Box>
            </Box>
          </BoxContainer>
        </Box>
      </Modal>
    </Box>
  );
}

export default ContratsASignerSection;
