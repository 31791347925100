import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import * as Yup from "yup";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { colors } from "../../theme/Colors";
import { Container, TextField, InputAdornment, IconButton } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useLogin } from "../../hooks/useLogin";
import { Link, useNavigate, useLocation, Navigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import { useAuthContext } from "../../hooks/useAuthContext";
import FormikErrorText from "../../components/ui/FormikErrorText";
import arrow from "../../imgs/imgV2/arrowOrange.svg";
import CarouselSignup from "../../components/ui/CarouselSignup/CarouselSignup";

import logo from "../../imgs/imgV2/flextalent.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Visibility, VisibilityOff } from "@mui/icons-material";

function Login() {
  const { user } = useAuthContext();

  //initialize error, isLoading, and login function
  const { login, errorLogin, isLoading } = useLogin();

  //validation for fields
  const validationSchema = Yup.object({
    email: Yup.string().email("Le format de l'email est invalide").required("Ce champs est obligatoire"),
    password: Yup.string().required("Ce champs est obligatoire"),
  });
  //Form initial values
  const initialValues = {
    email: "",
    password: "",
  };

  const onSubmitLogin = async (values, errorLogin, isLoading) => {
    await login(values.email, values.password);
  };

  const [showPassword, setShowPassword] = useState(false);

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  function handleMouseDownPassword(event) {
    event.preventDefault();
  }

  return (
    <Container maxWidth={"xl"}>
      <ToastContainer />
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
        <Box flex={1} display="flex" flexDirection="column" className="left" justifyContent="center" alignItems="center">
          <Box display="flex" width="100%" justifyContent="center" padding="32px 0px">
            <img width="210px" src={logo} alt="" />
          </Box>
          {/* FORM BEGINNING */}
          <Box sx={{ alignItems: "center", background: "white", width: "100%", maxWidth: "416px", padding: "16px", minHeight: "586px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <Box width="100%">
              <Box display="flex" flexDirection="column" textAlign="center" sx={{ marginBottom: "32px" }}>
                <Typography variant="text_xl" fontSize="25px" fontWeight="700">
                  Se connecter
                </Typography>
                <Typography variant="text_normal" color={colors.text_subtitle}>
                  Connectez-vous à votre compte
                </Typography>
              </Box>
              <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmitLogin}>
                {({ values, error }) => (
                  <Form>
                    <Box sx={{ marginBottom: "32px" }}>
                      <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
                        <Box sx={{ marginBottom: "6px" }}>
                          <Typography variant="text_normal" fontWeight="600" htmlFor="email" name="email">
                            Email
                          </Typography>
                          <Typography variant="text_normal" fontWeight="600" htmlFor="email" name="email" style={{ color: colors.main }}>
                            *
                          </Typography>
                        </Box>
                        <Field autoComplete="off" name="email" type="email" placeholder="votre@email.com" id="email" as={TextField}></Field>
                        <ErrorMessage name="email" component={FormikErrorText} />
                      </Box>

                      <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
                        <Box sx={{ marginBottom: "6px", display: "flex", justifyContent: "space-between" }}>
                          <Box>
                            <Typography variant="text_normal" fontWeight="600" htmlFor="password" name="password">
                              Mot de passe
                            </Typography>{" "}
                            <Typography variant="text_normal" fontWeight="600" htmlFor="email" name="email" style={{ color: colors.main }}>
                              *
                            </Typography>
                          </Box>
                          <Box display="flex" flexDirection="row-reverse">
                            <Link to="/password-recovery" style={{ textDecoration: "none" }}>
                              <Typography variant="text_clickable">Mot de passe oublié ?</Typography>
                            </Link>
                          </Box>
                        </Box>
                        {/* <Field autoComplete="off" name="password" type="password" placeholder="Saisir votre mot de passe" id="password" as={TextField} /> */}
                        <Field name="password" id="password">
                          {({ field, form, meta }) => (
                            <TextField
                              {...field}
                              fullWidth
                              placeholder="Saisir votre mot de passe"
                              autoComplete="Off"
                              type={showPassword ? "text" : "password"}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end" style={{ paddingRight: "12px" }}>
                                    <IconButton size="small" aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                                      {showPassword ? <Visibility fontSize="12px" htmlColor="#9499A8" /> : <VisibilityOff fontSize="12px" htmlColor="#9499A8" />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMessage name="password" component={FormikErrorText} />
                      </Box>
                    </Box>

                    <Box display="flex" justifyContent="center" alignItems="center">
                      <Button disabled={isLoading} className="submit" type="submit" variant="mj_primary_md" fullWidth>
                        Se connecter
                      </Button>
                    </Box>

                    {errorLogin && (
                      <Alert
                        sx={{
                          border: `1px solid  ${colors.mjRedOutline}`,
                          borderRadius: "12px",
                        }}
                        severity="error"
                      >
                        {errorLogin}
                      </Alert>
                    )}
                  </Form>
                )}
              </Formik>
            </Box>
            <Box>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography variant="text_normal" color={colors.text_subtitle} fontWeight="500" mr>
                  Vous n’avez pas de compte?
                  <Link to="/signup" style={{ textDecoration: "none" }}>
                    <Typography variant="text_normal" color={colors.main} fontWeight="500" sx={{ marginRight: "6px" }}>
                      {" "}
                    </Typography>
                    <Typography variant="text_normal" color={colors.main} fontWeight="500" sx={{ marginRight: "6px" }}>
                      Créer votre compte
                    </Typography>
                    <img src={arrow} alt="arrow" />
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Box>
          {/* FORM END */}
        </Box>
        <Box flex={1.1} className="right" sx={{ padding: "32px 32px 32px 0px" }} width="50%" display={{ lg: "flex", xs: "none" }}>
          <CarouselSignup />
        </Box>
      </Box>
    </Container>
  );
}

export default Login;
