import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import OffresSuggereSection from "./OffresSuggereSection";
import EntretienPrevuSection from "./EntretienPrevuSection";
import ReleveHeureSection from "./ReleveHeureSection";
import ContratsASignerSection from "./ContratsASignerSection";
import StatistiquesSection from "./Statistiques.Section";
import DayRecap from "./DayRecap";
import { useEffect } from "react";
import Api from "../../../../api/Api";
import { Urls } from "../../../../api/Urls";
import { ToastContainer, toast } from "react-toastify";
import { useState } from "react";

function TableauDeBordCandidat() {
  //invoke user
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [userFetch, setUserFetch] = useState({});
  useEffect(() => {
    Api.get(Urls.GET_USER_REC, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setUserFetch(res?.data?.user);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }, []);
  return (
    <Box display="flex" flexDirection="column" gap="24px">
      {/* title */}
      <ToastContainer />
      <Box>
        <Typography variant="text_xl" fontWeight="700" marginBottom="16px">
          Bonjour, {userFetch?.first_name} 🙂
        </Typography>
      </Box>

      {/*     <Box padding="24px" backgroundColor="#E6F0FF" display="flex" gap="8px" flexDirection="column" borderRadius="6px">
        <Box>
          <Typography fontSize="20px" fontWeight="700">
            📢 Annonce OhMyJob
          </Typography>
        </Box>
        <Box>
          <Typography variant="text_md">Turpis vitae sed tortor aenean porttitor. Feugiat quis cras et sit. Quis feugiat egestas sapien tincidunt ornare ipsum dictum nec. Venenatis vel etiam gravida pellentesque luctus quisque donec. Nulla risus.</Typography>
        </Box>
  </Box>*/}

      <Box>
        <Grid container spacing={2.5}>
          {/* left section */}
          <Grid item md={8.5} xs={12}>
            {/* OFFRES */}
            <Grid xs={12} marginBottom="20px">
              <Box>
                <OffresSuggereSection />
              </Box>
            </Grid>
          </Grid>

          {/* right section  */}

          <Grid item md={3.5} xs={12}>
            {/* Day recap */}
            <Grid item xs={12} marginBottom="20px">
              <DayRecap userFetch={userFetch} />
            </Grid>
            <Grid item xs={12} marginBottom="20px">
              <EntretienPrevuSection />
            </Grid>
            {/* Statistics */}
            <Grid item xs={12} marginBottom="20px">
              <StatistiquesSection />
            </Grid>
            {/* RELEVE DHEURE */}
            <Grid item xs={12} marginBottom="20px">
              <ReleveHeureSection />
            </Grid>
            {/* INTERIMAIRE EN POSTE */}
            <Grid item xs={12} marginBottom="20px">
              <ContratsASignerSection />
            </Grid>
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default TableauDeBordCandidat;
