export const country_codes = [
  { name: "Afghanistan", code: "AF" },
  { name: "Îles Åland", code: "AX" },
  { name: "Albanie", code: "AL" },
  { name: "Algérie", code: "DZ" },
  { name: "Samoa américaines", code: "AS" },
  { name: "Andorre", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctique", code: "AQ" },
  { name: "Antigua-et-Barbuda", code: "AG" },
  { name: "Argentine", code: "AR" },
  { name: "Arménie", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australie", code: "AU" },
  { name: "Autriche", code: "AT" },
  { name: "Azerbaïdjan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahreïn", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbade", code: "BB" },
  { name: "Biélorussie", code: "BY" },
  { name: "Belgique", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Bénin", code: "BJ" },
  { name: "Bermudes", code: "BM" },
  { name: "Bhoutan", code: "BT" },
  { name: "Bolivie", code: "BO" },
  { name: "Bosnie-Herzégovine", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Île Bouvet", code: "BV" },
  { name: "Brésil", code: "BR" },
  { name: "Territoire britannique de l'océan Indien", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgarie", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodge", code: "KH" },
  { name: "Cameroun", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cap-Vert", code: "CV" },
  { name: "Îles Caïmans", code: "KY" },
  { name: "République centrafricaine", code: "CF" },
  { name: "Tchad", code: "TD" },
  { name: "Chili", code: "CL" },
  { name: "Chine", code: "CN" },
  { name: "Île Christmas", code: "CX" },
  { name: "Îles Cocos (Keeling)", code: "CC" },
  { name: "Colombie", code: "CO" },
  { name: "Comores", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "République démocratique du Congo", code: "CD" },
  { name: "Îles Cook", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Côte d'Ivoire", code: "CI" },
  { name: "Croatie", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Chypre", code: "CY" },
  { name: "République tchèque", code: "CZ" },
  { name: "Danemark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominique", code: "DM" },
  { name: "République dominicaine", code: "DO" },
  { name: "Équateur", code: "EC" },
  { name: "Égypte", code: "EG" },
  { name: "Salvador", code: "SV" },
  { name: "Guinée équatoriale", code: "GQ" },
  { name: "Érythrée", code: "ER" },
  { name: "Estonie", code: "EE" },
  { name: "Éthiopie", code: "ET" },
  { name: "Îles Malouines (Falkland)", code: "FK" },
  { name: "Îles Féroé", code: "FO" },
  { name: "Fidji", code: "FJ" },
  { name: "Finlande", code: "FI" },
  { name: "France", code: "FR" },
  { name: "Guyane française", code: "GF" },
  { name: "Polynésie française", code: "PF" },
  { name: "Terres australes et antarctiques françaises", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambie", code: "GM" },
  { name: "Géorgie", code: "GE" },
  { name: "Allemagne", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Grèce", code: "GR" },
  { name: "Groenland", code: "GL" },
  { name: "Grenade", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernesey", code: "GG" },
  { name: "Guinée", code: "GN" },
  { name: "Guinée-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haïti", code: "HT" },
  { name: "Île Heard et îles McDonald", code: "HM" },
  { name: "Saint-Siège (État de la Cité du Vatican)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hongrie", code: "HU" },
  { name: "Islande", code: "IS" },
  { name: "Inde", code: "IN" },
  { name: "Indonésie", code: "ID" },
  { name: "Iran, République islamique d'", code: "IR" },
  { name: "Irak", code: "IQ" },
  { name: "Irlande", code: "IE" },
  { name: "Île de Man", code: "IM" },
  { name: "Israël", code: "IL" },
  { name: "Italie", code: "IT" },
  { name: "Jamaïque", code: "JM" },
  { name: "Japon", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordanie", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Corée, République populaire démocratique de", code: "KP" },
  { name: "Corée, République de", code: "KR" },
  { name: "Koweït", code: "KW" },
  { name: "Kirghizistan", code: "KG" },
  { name: "République démocratique populaire lao", code: "LA" },
  { name: "Lettonie", code: "LV" },
  { name: "Liban", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Libéria", code: "LR" },
  { name: "Libyenne Jamahiriya Arabe", code: "LY" }, // No translation for "Libyan Arab Jamahiriya" as it's a proper noun
  { name: "Liechtenstein", code: "LI" },
  { name: "Lituanie", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macédoine, l'ancienne République yougoslave de", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaisie", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malte", code: "MT" },
  { name: "Îles Marshall", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritanie", code: "MR" },
  { name: "Maurice", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexique", code: "MX" },
  { name: "Micronésie, États fédérés de", code: "FM" },
  { name: "Moldavie, République de", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolie", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Maroc", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibie", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Népal", code: "NP" },
  { name: "Pays-Bas", code: "NL" },
  { name: "Antilles néerlandaises", code: "AN" },
  { name: "Nouvelle-Calédonie", code: "NC" },
  { name: "Nouvelle-Zélande", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigéria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Île Norfolk", code: "NF" },
  { name: "Îles Mariannes du Nord", code: "MP" },
  { name: "Norvège", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palaos", code: "PW" },
  { name: "Territoire palestinien occupé", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papouasie-Nouvelle-Guinée", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Pérou", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Pologne", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Porto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Réunion", code: "RE" },
  { name: "Roumanie", code: "RO" },
  { name: "Fédération de Russie", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Sainte-Hélène", code: "SH" },
  { name: "Saint-Kitts-et-Nevis", code: "KN" },
  { name: "Sainte-Lucie", code: "LC" },
  { name: "Saint-Pierre-et-Miquelon", code: "PM" },
  { name: "Saint-Vincent-et-les-Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "Saint-Marin", code: "SM" },
  { name: "Sao Tomé-et-Principe", code: "ST" },
  { name: "Arabie saoudite", code: "SA" },
  { name: "Sénégal", code: "SN" },
  { name: "Serbie et Monténégro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapour", code: "SG" },
  { name: "Slovaquie", code: "SK" },
  { name: "Slovénie", code: "SI" },
  { name: "Îles Salomon", code: "SB" },
  { name: "Somalie", code: "SO" },
  { name: "Afrique du Sud", code: "ZA" },
  { name: "Géorgie du Sud-et-les Îles Sandwich du Sud", code: "GS" },
  { name: "Espagne", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Soudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard et Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Suède", code: "SE" },
  { name: "Suisse", code: "CH" },
  { name: "République arabe syrienne", code: "SY" },
  { name: "Taïwan, province de Chine", code: "TW" },
  { name: "Tadjikistan", code: "TJ" },
  { name: "République-Unie de Tanzanie", code: "TZ" },
  { name: "Thaïlande", code: "TH" },
  { name: "Timor oriental", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinité-et-Tobago", code: "TT" },
  { name: "Tunisie", code: "TN" },
  { name: "Turquie", code: "TR" },
  { name: "Turkménistan", code: "TM" },
  { name: "Îles Turques-et-Caïques", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Ouganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "Émirats arabes unis", code: "AE" },
  { name: "Royaume-Uni", code: "GB" },
  { name: "États-Unis", code: "US" },
  { name: "Îles mineures éloignées des États-Unis", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Ouzbékistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Îles Vierges britanniques", code: "VG" },
  { name: "Îles Vierges des États-Unis", code: "VI" },
  { name: "Wallis-et-Futuna", code: "WF" },
  { name: "Sahara occidental", code: "EH" },
  { name: "Yémen", code: "YE" },
  { name: "Zambie", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];
