import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, TextField, Button, Grid, InputAdornment, Autocomplete } from "@mui/material";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { colors } from "../../../../theme/Colors";
import FormikErrorText from "../../../../components/ui/FormikErrorText";
import * as Yup from "yup";
import Api from "../../../../api/Api";
import RechercheIcon from "../../../../imgs/imgV2/RechercheIcon.svg";

import { Urls } from "../../../../api/Urls";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { ToastContainer, toast } from "react-toastify";
import { debounce } from "lodash";
import axios from "axios";
import { country_codes } from "../../../../data/countryCodes";
function OBInfosSupplementaires({ setCurrentStep }) {
  //validation
  const formRef = useRef();
  const [pays, setPays] = useState({ name: "Algérie", code: "DZ" });

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const websiteRegExp = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
  const { user } = useAuthContext();
  const [openAd, setOpenAd] = useState(false);

  const [adresse, setAdresse] = useState("");
  const [adresses, setAdresses] = useState([]);

  const access_token = user?.access_token;
  const validationSchema = Yup.object({
    adresse: Yup.string().required("Ce champs est obligatoire"),
    phone: Yup.string().matches(phoneRegExp, "Numéro de téléphone invalide").required("Ce champs est obligatoire"),
    website: Yup.string().matches(websiteRegExp, "Url site web invalide"),
  });
  //Form initial values
  const [initialValues, setInitialValues] = useState({
    adresse: "",
    phone: "",
    website: "",
  });
  const [infosSupp, setInfosSupp] = useState({});
  const [initialValuesLoaded, setInitialValuesLoaded] = useState(false);

  useEffect(() => {
    Api.get(Urls.GET_USER_REC, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        //console.log(res, "info recruteur");
        setInfosSupp(res?.data?.user);
        setAdresse(res?.data?.user?.enterprise?.address ? res?.data?.user?.enterprise?.address : "");
        setInitialValues({
          adresse: res?.data?.user?.enterprise?.address ? res?.data?.user?.enterprise?.address : "",
          phone: res?.data?.user?.enterprise?.phone_number ? res?.data?.user?.enterprise?.phone_number : "",
          website: res?.data?.user?.enterprise?.website ? res?.data?.user?.enterprise?.website : "",
        });
        setInitialValuesLoaded(true);
        let paysSplit = res?.data?.user?.address?.split(",");
        let pays_name = paysSplit[paysSplit.length - 1];
        console.log(pays_name, "pays name");
        for (let index = 0; index < country_codes.length; index++) {
          const element = country_codes[index];
          if (element?.name === pays_name?.split(" ")[1]) {
            setPays(element);
          }
        }
        //console.log(res?.data?.user);
      })
      .catch((err) => toast.error(err?.response?.data?.message));
  }, []);
  const handleNext = () => {
    setCurrentStep(4);
  };

  const handlePrevious = () => {
    setCurrentStep(2);
  };

  const onSubmit = (values) => {
    //console.log(formRef?.current?.dirty);
    if (formRef?.current?.dirty) {
      //console.log(values);
      Api.patch(
        Urls?.UPDATE_PROFILE_ENTREPRISE + user?.user?.enterprise?.id,
        {
          address: values?.adresse,
          website: values?.website,
          phone_number: values?.phone,
        },
        {
          headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
        }
      )
        .then((res) => {
          //console.log(res);
          toast.success(res?.data?.message);
          handleNext();
        })
        .catch((err) => {
          //console.log(err);
          toast.error(err?.response?.data?.message);
        });
    } else {
      //console.log("not dirty");
      handleNext();
    }
  };
  const searchPlaces = (adresse, pays) => {
    if (adresse !== "") {
      axios
        .get("https://nominatim.openstreetmap.org/search", {
          params: {
            q: adresse,
            format: "json",
            polygon_kml: 0,
            addressdetails: 1,
            limit: 100,
            ...(pays && { countrycodes: `${pays?.code?.toLowerCase()}` }),
            "accept-language": "fr",
          },
        })
        .then((res) => {
          console.log(res);
          setAdresses(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const debouncedSearch = React.useMemo(() => {
    return debounce((adresse, pays) => {
      searchPlaces(adresse, pays);
    }, 500);
  }, []);
  useEffect(() => {
    debouncedSearch(adresse, pays);
  }, [adresse, pays]);
  return (
    <>
      <Formik innerRef={formRef} enableReinitialize={true} initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ values, setFieldValue }) => (
          <Form>
            <ToastContainer />
            <Box sx={{ marginBottom: "32px" }}>
              <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
                {pays !== null && (
                  <Box>
                    <Box marginBottom="6px">
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                        Pays
                      </Typography>
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                        *
                      </Typography>
                    </Box>
                    <Box>
                      <Autocomplete
                        disablePortal
                        fullWidth
                        value={pays}
                        id="combo-box-demo"
                        onChange={(e, value) => {
                          //console.log(value);
                          if (value) {
                            setPays(value);
                            setAdresse("");
                            setAdresses([]);
                          }
                        }}
                        options={country_codes}
                        getOptionLabel={(option) => option?.name}
                        isOptionEqualToValue={(option, value) => option === value}
                        renderInput={(params) => <TextField sx={{ maxWidth: "100%" }} {...params} variant="outlined" placeholder="Pays..." />}
                      />
                    </Box>
                  </Box>
                )}
                <Box sx={{ marginBottom: "6px" }}>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="address" name="adresse">
                    Adresse
                  </Typography>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="address" name="adresse" style={{ color: colors.main }}>
                    *
                  </Typography>
                </Box>
                <TextField
                  sx={{ width: "100%" }}
                  value={adresse}
                  type="text"
                  onClick={() => {
                    setOpenAd(true);
                  }}
                  fullWidth
                  onChange={(e) => {
                    setAdresse(e.target.value);
                  }}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={RechercheIcon} alt="end" />
                      </InputAdornment>
                    ),
                    endAdornment: null,
                  }}
                  placeholder="Adresse..."
                />
                {openAd && adresse && (
                  <Box boxShadow="0px 1px 5px 0px black" marginTop="2px" width="100%" maxHeight="300px" sx={{ overflowY: "auto" }}>
                    {adresses?.map((ad) => (
                      <Box
                        onClick={() => {
                          setAdresse(ad?.display_name);
                          setFieldValue("adresse", ad?.display_name);
                          setOpenAd(false);
                        }}
                        padding="5px"
                        sx={{ ":hover": { filter: "brightness(0.95)", backgroundColor: "white" }, cursor: "pointer" }}
                      >
                        <Typography key={ad?.id}>{ad.display_name}</Typography>
                      </Box>
                    ))}
                  </Box>
                )}{" "}
                <ErrorMessage name="adresse" component={FormikErrorText} />
              </Box>

              <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
                <Box sx={{ marginBottom: "6px" }}>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="phone" name="phone">
                    Numéro de téléphone
                  </Typography>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="phone" name="phone" style={{ color: colors.main }}>
                    *
                  </Typography>
                </Box>
                <Field autoComplete="off" name="phone" type="text" placeholder="+33 000 000 000" id="phone" as={TextField}></Field>
                <ErrorMessage name="phone" component={FormikErrorText} />
              </Box>

              <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
                <Box sx={{ marginBottom: "6px" }}>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="website" name="website">
                    Site web
                  </Typography>
                </Box>
                <Field autoComplete="off" name="website" type="text" placeholder="www.votresiteweb.com" id="website" as={TextField}></Field>
                <ErrorMessage name="website" component={FormikErrorText} />
              </Box>
            </Box>

            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Button sx={{ width: "118px" }} onClick={() => handlePrevious()} variant="mj_secondary_md">
                  Précédent
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button sx={{ width: "118px" }} type="submit" variant="mj_green_md">
                  Enregistrer
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button sx={{ width: "118px" }} variant="mj_primary_md" onClick={() => handleNext()}>
                  Suivant
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default OBInfosSupplementaires;
