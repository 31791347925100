import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Autocomplete, Box, Button, Divider, InputAdornment, Modal, TextField, Typography } from "@mui/material";
import { colors } from "../../../../theme/Colors";
import { Field, Form, Formik } from "formik";
import ControlPointRoundedIcon from "@mui/icons-material/ControlPointRounded";

import MyTextField from "../../../../components/ui/MyTextField";
import MyDPFullDate from "../../../../components/ui/MyDPFullDate";
import Api from "../../../../api/Api";
import RechercheIcon from "../../../../imgs/imgV2/RechercheIcon.svg";
import { Urls } from "../../../../api/Urls";
import { useRef } from "react";
import TableauDeBord from "../tableau-de-bord/TableauDeBordCandidat";
import { modalStyle } from "../../../../theme/ModalStyle";
import ButtonSquare from "../../../../components/ui/ButtonSquare";
import { debounce } from "lodash";
import BoxContainer from "../../../../components/ui/BoxContainer";
import axios from "axios";
import { country_codes } from "../../../../data/countryCodes";
function OnBoardingCoordonnee({ setCurrentStep }) {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [userFetch, setUSerFetch] = useState();
  const [openAd, setOpenAd] = useState(false);
  const [pays, setPays] = useState({ name: "Algérie", code: "DZ" });
  const [change, setChange] = useState(false);
  const formikRef = useRef();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState();
  const [disabledMetier, setDisabledMetier] = useState(false);
  const [metierID, setMetierID] = useState();
  const [newMetier, setNewMetier] = useState([]);
  const [selectedAdresseInfoP, setselectedAdresseInfoP] = useState({});
  //console.log(user, "this is our user");
  const [adresse, setAdresse] = useState("");
  const [is_first_search, setIsFirstSearch] = useState(true);
  const [adresses, setAdresses] = useState([]);
  const [PatchMetier, setPatchMetier] = useState([]);
  const [metierDelte, setMetierDelete] = useState([]);
  const handleNext = () => {
    setCurrentStep(2);
  };
  //validation schema fot the form
  const validationSchema = Yup.object().shape({
    titre: Yup.object().shape({
      name: Yup.string().required("Ce champ est obligatoire"),
    }),
    anneeExp: Yup.number().typeError("Valeur invalide").min(0, "Valeur invalide").required("Ce champ est obligatoire"),
  });
  //validation schema fot the update form
  const validationSchemaUpdate = Yup.object().shape({
    anneeExp: Yup.number().typeError("Valeur invalide").min(0, "Valeur invalide").required("Ce champ est obligatoire"),
  });
  const [initialValues, setInitialValues] = useState({
    titre: "",
    anneeExp: "",
  });
  //initial values for the infos personnelle form
  const [initialValuesInfoP, setInitialValuesinfoP] = useState({});
  const [metier, setMetier] = useState([]);

  useEffect(() => {
    Api.get(Urls.GET_USER_REC, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        //console.log(res, "user");
        setUSerFetch(res?.data);
        setAdresse(res?.data?.user?.address ? res?.data?.user?.address : "");
        setselectedAdresseInfoP({
          id: "",
          display_name: res?.data?.user?.address ? res?.data?.user?.address : "",
        });
        setOpenAd(false);
        setInitialValuesinfoP({
          date_de_naissance: res?.data?.user?.birthday ? res?.data?.user?.birthday : "",
          adresse: res?.data?.user?.address,
          numerp_de_tel: res?.data?.user?.phone_number,
        });
        let paysSplit = res?.data?.user?.address?.split(",");
        let pays_name = paysSplit[paysSplit.length - 1];
        console.log(pays_name, "pays name");
        for (let index = 0; index < country_codes.length; index++) {
          const element = country_codes[index];
          if (element?.name === pays_name?.split(" ")[1]) {
            setPays(element);
          }
        }
      })
      .catch((err) => toast.error(err?.response?.data?.message));
  }, []);
  //validation schema for the infos personnelle form
  const validationSchemaInfoPers = Yup.object({
    adresse: Yup.string().required("Ce champ est obligatoire"),
    date_de_naissance: Yup.date().required("Ce champ est obligatoire"),
    numerp_de_tel: Yup.string()
      .required("Ce champ est obligatoire")
      .matches(/^(\+\d{1,3})?\s*(\d{9,10})$/, "Numéro de téléphone invalide"),
  });
  //function to modify the profile
  const ModifyProfile = (values) => {
    //console.log(values, "form values");
    Api.patch(
      Urls?.MODIFY_INTERIMAIRE + user?.user?.id + "/interim",
      {
        birthday: values.date_de_naissance,
        address: values.adresse,
        phone_number: values.numerp_de_tel,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        //console.log(res);
        toast.success(res?.data?.message);
        handleNext();
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.data?.message);
      });
  };
  //search metier
  const [metierListe, setMetierListe] = useState([]);
  const searchMetier = (value) => {
    Api.get(Urls.GET_METIER_AUTO, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        search: value,
      },
    })
      .then((res) => {
        //console.log(res, "search resposne");
        setMetierListe(res?.data?.metiers);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  //Fetch user metier
  const [data, setData] = useState();
  useEffect(() => {
    Api.get(Urls.GET_USER_METIER + user?.user?.id + "/metiers", {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        perPage: 20,
      },
    })
      .then((res) => {
        setMetier(res?.data?.metiers);
        //console.log(res);
        setData(res?.data);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
      });
  }, [change]);
  //ajouter un nouveau metier
  const metierAdd = (values) => {
    //console.log(values);
    Api.post(
      Urls?.ADD_USER_METIER + user?.user?.id + "/metiers",
      {
        metier_id: values?.titre?.id,
        experience_years: values.anneeExp,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        //console.log(res);
        toast.success(res?.data?.message);
        setChange(!change);
        setOpen(false);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
  //modifier un metier
  const metierUpdate = (values) => {
    //console.log(values);
    Api.patch(
      Urls?.ADD_USER_METIER + user?.user?.id + "/metiers/" + values?.id,
      {
        experience_years: values.experience_years,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        //console.log(res);
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
  //function to handle when click on the add icon
  const handleAddClick = () => {
    setInitialValues({
      anneeExp: "",
    });
    setOpen(true);
    setDisabledMetier(false);
  };
  //function to handle when click on the modify icon
  const handleModifyClick = (met) => {
    const metier = {
      id: met.id,
      name: met.name,
      experience_years: met.experience_years,
    };
    setInitialValues({
      titre: metier,
      anneeExp: met.experience_years,
    });
    setOpen(true);
    setDisabledMetier(true);
    setMetierID(met);
  };
  //handle pagination
  const handlePagination = () => {
    Api.get(`${data.links.next}`, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        perPage: 3,
      },
    }).then((res, err) => {
      if (res?.request?.status === 200) {
        setMetier(metier.concat(res?.data?.metiers));
        setData(res?.data);
      }
      if (err) {
        toast.error(err?.response?.data?.message);
      }
    });
  };
  //supprimer un metier
  const deleteMetier = (element) => {
    Api.delete(Urls.DELETE_USER_METIER + user?.user?.id + "/metiers/" + element, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  const handleDelete = () => {
    if (metierID?.new) {
      const filteredLis = newMetier.filter((metier) => metier?.titre?.name !== metierID?.name);
      const filteredMetier = metier?.filter((metier) => metier?.id !== metierID?.id);
      setMetier(filteredMetier);
      setNewMetier(filteredLis);
    } else {
      const filteredMetier = metier?.filter((metier) => metier?.id !== metierID?.id);
      setMetier(filteredMetier);
      setMetierDelete([...metierDelte, metierID?.id]);
      setOpenDelete(false);
      setMetierID();
    }
  };
  const executeSave = () => {
    if (newMetier?.length > 0) {
      for (let index = 0; index < newMetier.length; index++) {
        const element = newMetier[index];
        metierAdd(element);
      }
    }
    if (PatchMetier?.length > 0) {
      for (let index = 0; index < PatchMetier.length; index++) {
        const element = PatchMetier[index];
        metierUpdate(element);
      }
    }
    if (metierDelte?.length > 0) {
      for (let index = 0; index < metierDelte.length; index++) {
        const element = metierDelte[index];
        deleteMetier(element);
      }
    }
  };
  const searchPlaces = (adresse, pays) => {
    if (adresse !== "") {
      axios
        .get("https://nominatim.openstreetmap.org/search", {
          params: {
            q: adresse,
            format: "json",
            polygon_kml: 0,
            addressdetails: 1,
            limit: 100,
            ...(pays && { countrycodes: `${pays?.code?.toLowerCase()}` }),
            "accept-language": "fr",
          },
        })
        .then((res) => {
          console.log(res);
          setAdresses(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const debouncedSearch = React.useMemo(() => {
    return debounce((adresse, pays) => {
      searchPlaces(adresse, pays);
    }, 500);
  }, []);
  useEffect(() => {
    debouncedSearch(adresse, pays);
  }, [adresse, pays]);
  //console.log("new metier", newMetier);
  return (
    <>
      <Formik
        innerRef={formikRef}
        enableReinitialize={true}
        initialValues={initialValuesInfoP}
        onSubmit={(values) => {
          ModifyProfile(values);
          executeSave();
        }}
        validationSchema={validationSchemaInfoPers}
      >
        {({ values, errors, setFieldValue, touched }) => (
          <Form>
            <Box display="flex" flexDirection="column" gap="20px">
              <Box width="100%">
                <Box marginBottom="6px">
                  <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                    Numéro de téléphone
                  </Typography>
                  <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                    *
                  </Typography>
                </Box>
                <MyTextField setFieldValue={setFieldValue} value={values.numerp_de_tel} name="numerp_de_tel" placeholder="Numéro de téléphone..." />
                {errors.numerp_de_tel && touched.numerp_de_tel ? (
                  <Typography color="red" fontSize="12px">
                    {errors.numerp_de_tel}
                  </Typography>
                ) : null}
              </Box>
              <Box width="100%">
                <Box marginBottom="6px">
                  <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                    Date de naissance
                  </Typography>
                  <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                    *
                  </Typography>
                </Box>
                <Field value={values.date_de_naissance} setFieldValue={setFieldValue} name="date_de_naissance" placeholder="Date..." as={MyDPFullDate}></Field>
                {errors.date_de_naissance && touched.date_de_naissance ? (
                  <Typography color="red" fontSize="12px">
                    {errors.date_de_naissance}
                  </Typography>
                ) : null}
              </Box>
              {pays !== null && (
                <Box>
                  <Box marginBottom="6px">
                    <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                      Pays
                    </Typography>
                    <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                      *
                    </Typography>
                  </Box>
                  <Box>
                    <Autocomplete
                      disablePortal
                      fullWidth
                      value={pays}
                      id="combo-box-demo"
                      onChange={(e, value) => {
                        //console.log(value);
                        if (value) {
                          setPays(value);
                          setAdresse("");
                          setAdresses([]);
                        }
                      }}
                      options={country_codes}
                      getOptionLabel={(option) => option?.name}
                      isOptionEqualToValue={(option, value) => option === value}
                      renderInput={(params) => <TextField sx={{ maxWidth: "100%" }} {...params} variant="outlined" placeholder="Pays..." />}
                    />
                  </Box>
                </Box>
              )}
              <Box width="100%">
                <Box marginBottom="6px">
                  <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                    Adresse
                  </Typography>
                  <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                    *
                  </Typography>
                </Box>
                <TextField
                  sx={{ width: "100%" }}
                  value={adresse}
                  type="text"
                  onClick={() => {
                    setOpenAd(true);
                  }}
                  fullWidth
                  onChange={(e) => {
                    setAdresse(e.target.value);
                  }}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={RechercheIcon} alt="end" />
                      </InputAdornment>
                    ),
                    endAdornment: null,
                  }}
                  placeholder="Adresse..."
                />

                {openAd && adresse && (
                  <Box boxShadow="0px 1px 5px 0px black" marginTop="2px" width="100%" maxHeight="300px" sx={{ overflowY: "auto" }}>
                    {adresses?.map((ad) => (
                      <Box
                        onClick={() => {
                          setAdresse(ad?.display_name);
                          setFieldValue("adresse", ad?.display_name);
                          setOpenAd(false);
                        }}
                        padding="5px"
                        sx={{ ":hover": { filter: "brightness(0.95)", backgroundColor: "white" }, cursor: "pointer" }}
                      >
                        <Typography key={ad?.id}>{ad.display_name}</Typography>
                      </Box>
                    ))}
                  </Box>
                )}
                {errors.adresse && touched.adresse ? (
                  <Typography color="red" fontSize="12px">
                    {errors.adresse}
                  </Typography>
                ) : null}
              </Box>
            </Box>
            <Divider sx={{ margin: "32px 0px" }} />
            <Box marginBottom="20px" display="flex" justifyContent="space-between" alignItems="center">
              <Box>
                <Typography fontSize="20px" fontWeight="700" lineHeight="160%">
                  Métiers
                </Typography>
              </Box>
              <Button variant="mj_secondary_md" onClick={handleAddClick}>
                Ajouter
              </Button>
            </Box>
            <Box marginBottom="32px">
              <Box display="flex" flexDirection="column" gap="20px">
                {metier.map((met) => (
                  <Box key={met.id} display="flex" alignItems="center" justifyContent="space-between">
                    <Box>
                      <Typography fontWeight="600" fontSize="14px" lineHeight="160%">
                        {met.name}
                      </Typography>
                      <Typography fontSize="12px" fontWeight="400" lineHeight="160%" color="#666D83">
                        {met.experience_years} Ans
                      </Typography>
                    </Box>
                    <Box display="flex">
                      <ButtonSquare onClick={() => handleModifyClick(met)} action="modify" />
                      <ButtonSquare
                        onClick={() => {
                          setOpenDelete(true);
                          setMetierID(met);
                        }}
                        action="delete"
                      />
                    </Box>
                  </Box>
                ))}
              </Box>
              {data?.links?.next && (
                <Box sx={{ cursor: "pointer" }} display="flex" justifyContent="center" alignItems="center">
                  <ControlPointRoundedIcon fontSize="large" sx={{ color: `${colors.main}` }} onClick={handlePagination} />
                </Box>
              )}
            </Box>
            <Box display="flex" gap="12px" justifyContent="flex-end" alignItems="center">
              <Box>
                <Button variant="mj_primary_md" type="submit">
                  Enregistrer
                </Button>
              </Box>
              <Box>
                <Button variant="mj_primary_md" onClick={handleNext}>
                  Suivant
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Métier
              </Typography>
              <Box display="flex" alignItems="center" gap="10px">
                <ButtonSquare onClick={() => setOpen(false)} action="close" />
              </Box>
            </Box>
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              onSubmit={(values) => {
                //console.log("submitted");
                if (metierID) {
                  if (metierID?.new) {
                    //console.log(newMetier, "nouveau metiers");
                    //console.log(metier, "metier existant");
                    //console.log(metierID, "metier existant selectionner");
                    const filteredListeNew = newMetier.filter((metier) => metier?.titre?.id !== metierID?.id);
                    const filteredListe = metier.filter((metier) => metier?.id !== metierID?.id);

                    //console.log(filteredListeNew, "new liste after filtering");
                    //console.log(filteredListe, "new liste after filtering");
                    filteredListe.push({
                      id: values?.titre?.name,
                      name: values?.titre?.name,
                      experience_years: values?.anneeExp,
                      new: true,
                    });
                    filteredListeNew.push(values);
                    setMetier(filteredListe);
                    setNewMetier(filteredListeNew);
                    setOpen(false);
                  } else {
                    setPatchMetier([
                      ...PatchMetier,
                      {
                        id: metierID?.id,
                        experience_years: values?.anneeExp,
                      },
                    ]);
                    const filteredMtier = metier?.filter((metier) => metier.id !== metierID?.id);
                    filteredMtier.push({ id: metierID?.id, experience_years: values?.anneeExp, name: values?.titre?.name });
                    setMetier(filteredMtier);
                    setOpen(false);
                    setMetierID();
                  }
                } else {
                  let pass = true;
                  for (let index = 0; index < metier.length; index++) {
                    const element = metier[index];
                    if (element?.name === values?.titre?.name) {
                      pass = false;
                    }
                  }
                  if (pass) {
                    setNewMetier([...newMetier, values]);
                    const metierLis = metier;
                    metierLis.push({
                      id: values?.titre?.id,
                      name: values?.titre?.name,
                      experience_years: values?.anneeExp,
                      new: true,
                    });
                    setMetier(metierLis);
                    setOpen(false);
                  }
                  setOpen(false);
                }
              }}
              validationSchema={metierID ? validationSchemaUpdate : validationSchema}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <Box gap="20px" display="flex" flexDirection="column">
                    <Box>
                      <Box marginBottom="6px">
                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                          Titre
                        </Typography>
                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                          *
                        </Typography>
                      </Box>
                      <Box>
                        <Autocomplete
                          disablePortal
                          fullWidth
                          disabled={disabledMetier}
                          id="combo-box-demo"
                          value={values.titre}
                          onChange={(e, value) => {
                            //console.log(value);
                            if (value) {
                              setFieldValue("titre", value);
                            }
                          }}
                          options={metierListe}
                          getOptionLabel={(Option) => Option.name}
                          renderInput={(params) => (
                            <TextField
                              sx={{ maxWidth: "100%" }}
                              {...params}
                              variant="outlined"
                              value={values?.titre}
                              onChange={(e) => {
                                if (e.target.value.length > 3) {
                                  searchMetier(e.target.value);
                                } else {
                                  setMetierListe([]);
                                }
                              }}
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src={RechercheIcon} alt="end" />
                                  </InputAdornment>
                                ),
                                endAdornment: null,
                              }}
                              placeholder="Métier..."
                            />
                          )}
                        />
                        <Box flexWrap="wrap" display="flex" gap="10px" margin="10px 0px"></Box>
                      </Box>
                    </Box>
                    <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Années d'expérience
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <MyTextField setFieldValue={setFieldValue} value={values.anneeExp} name="anneeExp" placeholder="Années d'expérience..." />
                        {errors.anneeExp && touched.anneeExp ? (
                          <Typography color="red" fontSize="12px">
                            {errors.anneeExp}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                    <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                      <Button onClick={() => setOpen(false)} variant="mj_secondary_md">
                        Annuler
                      </Button>
                      <Button type="submit" variant="mj_primary_md">
                        Enregistrer
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </BoxContainer>
        </Box>
      </Modal>
      <Modal open={openDelete} onClose={() => setOpenDelete(false)}>
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Supprimer un métier
              </Typography>
              <ButtonSquare onClick={() => setOpenDelete(false)} action="close" />
            </Box>
            <Box display="flex" flexDirection="column" gap="24px">
              <Box>
                <Typography sx={{ fontSize: "14px", fontWeight: "600", lineHeight: "160%" }}>Êtes-vous sûr de bien vouloir supprimer ce métier</Typography>
              </Box>
              <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                <Button onClick={() => setOpenDelete(false)} variant="mj_secondary_md">
                  Annuler
                </Button>
                <Button onClick={handleDelete} variant="mj_danger_md">
                  Supprimer
                </Button>
              </Box>
            </Box>
          </BoxContainer>
        </Box>
      </Modal>
    </>
  );
}

export default OnBoardingCoordonnee;
