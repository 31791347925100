import React, { useEffect, useRef, useState } from "react";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { Autocomplete, Box, Button, InputAdornment, TextField, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import MyTextField from "../../../../components/ui/MyTextField";
import { colors } from "../../../../theme/Colors";
import { toast } from "react-toastify";
import Api from "../../../../api/Api";
import { debounce } from "lodash";
import RechercheIcon from "../../../../imgs/imgV2/RechercheIcon.svg";

import { Urls } from "../../../../api/Urls";
import * as Yup from "yup";
import axios from "axios";
import MapComponent from "../../../../components/mapComponent/MapComponent";
import { country_codes } from "../../../../data/countryCodes";
function OnBoadingpref({ setCurrentStep }) {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const formikRef = useRef();
  const [selectedAdresseInfoP, setselectedAdresseInfoP] = useState({});
  const [zoom_level, setZoom_level] = useState(15);
  const [openAd, setOpenAd] = useState(false);
  //console.log(user, "this is our user");
  const [adresse, setAdresse] = useState("");
  const [radius, setRadius] = useState(0);
  const [position, setPosition] = useState([48.864716, 2.349014]);
  const [radiusInKm, setRadiusInKm] = useState(0);
  const [adresses, setAdresses] = useState([]);
  const [pays, setPays] = useState({ name: "Algérie", code: "DZ" });

  const [selectedAdresse, setselectedAdresse] = useState({});
  //validation schema fot the form
  const validationSchema = Yup.object().shape({
    adresse: Yup.string().required("Ce champ est obligatoire"),
    rayon: Yup.number().required("Ce champ est obligatoire").positive("Rayon invalide"),
    taux_horraire: Yup.number().required("Ce champ est obligatoire").positive("Taux invalide"),
  });
  const handleNext = () => {
    setCurrentStep(3);
  };
  const handlePrevious = () => {
    setCurrentStep(1);
  };
  const ModifyPref = (values) => {
    console.log(selectedAdresse, "selected adresse when submiting");
    //console.log(values, "form values");
    Api.patch(
      Urls?.MODIFY_PROFILE + user?.user?.profile?.id,
      {
        travel_longitude: selectedAdresse?.lon,
        travel_latitude: selectedAdresse?.lat,
        travel_radius: values.rayon,
        preferred_address: values.adresse,
        desired_hourly_rate: values.taux_horraire,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        //console.log(res);
        toast.success(res?.data?.message);
        handleNext();
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.data?.message);
        handleNext();
      });
  };
  const [initialValues, setInitialValues] = useState({});
  useEffect(() => {
    Api.get(Urls.GET_USER_REC, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        //console.log(res, "user");
        setInitialValues({
          adresse: res?.data?.user?.profile?.preferred_address ? res?.data?.user?.profile?.preferred_address : res?.data?.user?.address,
          rayon: res?.data?.user?.profile?.travel_radius,
          taux_horraire: res?.data?.user?.profile?.desired_hourly_rate,
        });
        setselectedAdresseInfoP({
          id: "",
          display_name: res?.data?.user?.address ? res?.data?.user?.address : "",
        });
        setselectedAdresse({
          id: "",
          display_name: res?.data?.user?.profile?.preferred_address ? res?.data?.user?.profile?.preferred_address : res?.data?.user?.address,
          lat: res?.data?.user?.profile?.travel_latitude ? res?.data?.user?.profile?.travel_latitude : 48.864716,
          lon: res?.data?.user?.profile?.travel_longitude ? res?.data?.user?.profile?.travel_longitude : 2.349014,
        });
        setRadius(res?.data?.user?.profile?.travel_radius ? res?.data?.user?.profile?.travel_radius : 0);
        setRadiusInKm(res?.data?.user?.profile?.travel_radius ? calculate_pixels_in_screen(res?.data?.user?.profile?.travel_radius, zoom_level) : 0);
        setPosition([res?.data?.user?.profile?.travel_latitude ? res?.data?.user?.profile?.travel_latitude : 48.864716, res?.data?.user?.profile?.travel_longitude ? res?.data?.user?.profile?.travel_longitude : 2.349014]);
        setAdresse(res?.data?.user?.profile?.preferred_address ? res?.data?.user?.profile?.preferred_address : res?.data?.user?.address);
        let paysSplit = res?.data?.user?.profile?.preferred_address ? res?.data?.user?.profile?.preferred_address.split(",") : res?.data?.user?.address?.split(",");
        let pays_name = paysSplit[paysSplit.length - 1];
        console.log(pays_name, "pays name");
        for (let index = 0; index < country_codes.length; index++) {
          const element = country_codes[index];
          if (element?.name === pays_name?.split(" ")[1]) {
            setPays(element);
          }
        }
      })
      .catch((err) => toast.error(err?.response?.data?.message));
  }, []);
  const searchPlaces = (adresse, pays) => {
    if (adresse !== "") {
      axios
        .get("https://nominatim.openstreetmap.org/search", {
          params: {
            q: adresse,
            format: "json",
            polygon_kml: 0,
            addressdetails: 1,
            limit: 100,
            ...(pays && { countrycodes: `${pays?.code?.toLowerCase()}` }),
            "accept-language": "fr",
          },
        })
        .then((res) => {
          console.log(res);
          setAdresses(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  function calculate_pixels_in_screen(km, zoom_level) {
    const circumference_of_earth = 40075.004;
    const pixels_per_km = (circumference_of_earth / 2 ** zoom_level) * 256;
    const pixels_in_screen = km * pixels_per_km;
    return pixels_in_screen;
  }
  const debouncedSearch = React.useMemo(() => {
    return debounce((adresse, pays) => {
      searchPlaces(adresse, pays);
    }, 500);
  }, []);

  useEffect(() => {
    debouncedSearch(adresse, pays);
    console.log(adresse);
  }, [adresse, pays]);

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        onSubmit={(values) => {
          ModifyPref(values);
        }}
        enableReinitialize={true}
        validationSchema={validationSchema}
      >
        {({ values, errors, setFieldValue, touched }) => (
          <Form>
            <Box gap="20px" display="flex" flexDirection="column">
              <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                {pays !== null && (
                  <Box>
                    <Box marginBottom="6px">
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                        Pays
                      </Typography>
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                        *
                      </Typography>
                    </Box>
                    <Box>
                      <Autocomplete
                        disablePortal
                        fullWidth
                        value={pays}
                        id="combo-box-demo"
                        onChange={(e, value) => {
                          //console.log(value);
                          if (value) {
                            setPays(value);
                            setAdresse("");
                            setAdresses([]);
                          }
                        }}
                        options={country_codes}
                        getOptionLabel={(option) => option?.name}
                        isOptionEqualToValue={(option, value) => option === value}
                        renderInput={(params) => <TextField sx={{ maxWidth: "100%" }} {...params} variant="outlined" placeholder="Pays..." />}
                      />
                    </Box>
                  </Box>
                )}
                <Box width="100%">
                  <Box marginBottom="6px">
                    <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                      Adresse
                    </Typography>
                    <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                      *
                    </Typography>
                  </Box>
                  <TextField
                    sx={{ width: "100%" }}
                    value={adresse}
                    type="text"
                    fullWidth
                    onClick={() => {
                      setOpenAd(true);
                    }}
                    onChange={(e) => {
                      setAdresse(e.target.value);
                    }}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={RechercheIcon} alt="end" />
                        </InputAdornment>
                      ),
                      endAdornment: null,
                    }}
                    placeholder="Adresse..."
                  />

                  {openAd && adresse && (
                    <Box boxShadow="0px 1px 5px 0px black" marginTop="2px" width="100%" maxHeight="300px" sx={{ overflowY: "auto" }}>
                      {adresses?.map((ad) => (
                        <Box
                          onClick={() => {
                            setAdresse(ad?.display_name);
                            setFieldValue("adresse", ad?.display_name);
                            setselectedAdresse(ad);
                            setPosition([ad?.lat, ad?.lon]);
                            setOpenAd(false);
                          }}
                          padding="5px"
                          sx={{ ":hover": { filter: "brightness(0.95)", backgroundColor: "white" }, cursor: "pointer" }}
                        >
                          <Typography key={ad?.id}>{ad.display_name}</Typography>
                        </Box>
                      ))}
                    </Box>
                  )}
                  {errors.adresse && touched.adresse ? (
                    <Typography color="red" fontSize="12px">
                      {errors.adresse}
                    </Typography>
                  ) : null}
                </Box>
              </Box>
              <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                <Box maxWidth="300px">
                  <Box marginBottom="6px">
                    <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                      Rayon de déplacement
                    </Typography>
                    <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                      *
                    </Typography>
                  </Box>
                  <TextField
                    onChange={(e) => {
                      setFieldValue("rayon", e.target?.value);
                      setRadiusInKm(calculate_pixels_in_screen(e.target.value, zoom_level));
                      setRadius(e.target.value);
                    }}
                    endAdornment="km"
                    value={values.rayon}
                    name="rayon"
                    placeholder="Rayon..."
                  />{" "}
                  {errors.rayon && touched.rayon ? (
                    <Typography color="red" fontSize="12px">
                      {errors.rayon}
                    </Typography>
                  ) : null}
                </Box>
              </Box>
              <MapComponent is_radius={true} radius={radius} position={position} setRadiusInKm={setRadiusInKm} setZoom_level={setZoom_level} zoom_level={zoom_level} radiusInKm={radiusInKm} selectedAdresse={selectedAdresse} />
              <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                <Box maxWidth="300px">
                  <Box marginBottom="6px">
                    <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                      Tranche de taux horaire (Euro)
                    </Typography>
                    <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                      *
                    </Typography>
                  </Box>
                  <MyTextField endAdornment="hour" setFieldValue={setFieldValue} value={values.taux_horraire} name="taux_horraire" placeholder="Taux..." />
                  {errors.taux_horraire && touched.taux_horraire ? (
                    <Typography color="red" fontSize="12px">
                      {errors.taux_horraire}
                    </Typography>
                  ) : null}
                </Box>
              </Box>
            </Box>
            <Box marginTop="32px" display="flex" justifyContent="flex-end" alignItems="center" gap="12px">
              <Box>
                <Button variant="mj_secondary_md" onClick={handlePrevious}>
                  Précédent
                </Button>
              </Box>
              <Box>
                <Button variant="mj_primary_md" type="submit">
                  {" "}
                  Enregistrer
                </Button>
              </Box>
              <Box>
                <Button variant="mj_primary_md" onClick={handleNext}>
                  Suivant
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default OnBoadingpref;
