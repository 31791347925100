import React from "react";
import { useState, useEffect } from "react";
import { colors } from "../../../theme/Colors";
import { Box, Button, Typography, Avatar, IconButton, TextField, InputLabel, FormControl, Select, MenuItem, CircularProgress } from "@mui/material";
import styled from "@emotion/styled";

import { useAuthContext } from "../../../hooks/useAuthContext";

import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import ArrowRightIcon from "../../../imgs/imgV2/ArrowRight.svg";
import { useNavigate } from "react-router-dom";

import InfosPersonnelle from "../../../components/fonctionnel/candidat/MonProfil/InfosPersonnelle";
import InfoAdministrative from "../../../components/fonctionnel/candidat/MonProfil/InfoAdministrative";
import MetierCV from "../../../components/fonctionnel/candidat/MonProfil/MetierCV";
import Metier from "../../../components/fonctionnel/candidat/MonProfil/Metier";
import Certification from "../../../components/fonctionnel/candidat/MonProfil/Certification";
import Scolarite from "../../../components/fonctionnel/candidat/MonProfil/Scolarite";
import Documents from "../../../components/fonctionnel/candidat/MonProfil/Documents";

function ProfileCandidat() {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [UserFetch, setUserFetch] = useState({});
  const [usert, setUsert] = useState({});
  const [loading, setLoading] = useState(true);
  const [updateBord, setUpdateBoard] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    Api.get(Urls.GET_USER_REC, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setUserFetch(res?.data?.user?.profile);
        setUsert(res?.data?.user);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  }, [updateBord]);
  const colorSelector = () => {
    if (UserFetch?.valid_steps?.step_1 && UserFetch?.valid_steps?.step_2 && UserFetch?.valid_steps?.step_3 && UserFetch?.valid_steps?.step_4 && UserFetch?.valid_steps?.step_5) {
      return true;
    } else {
      return false;
    }
  };
  const stepSelector = () => {
    if (!UserFetch?.valid_steps?.step_1) {
      return "Coordonnées";
    }
    if (!UserFetch?.valid_steps?.step_2) {
      return "Préférences";
    }
    if (!UserFetch?.valid_steps?.step_3) {
      return "CV";
    }
    if (!UserFetch?.valid_steps?.step_4) {
      return "Certifications / Habilitations & Scolarité";
    }
    if (!UserFetch?.valid_steps?.step_5) {
      return "Informations administratives";
    }
  };
  const handleClickButton = (value) => {
    if (value === "Coordonnées") {
      navigate("/renard/onboarding", { state: { step: 1 } });
    }
    if (value === "Préférences") {
      navigate("/renard/onboarding", { state: { step: 2 } });
    }
    if (value === "CV") {
      navigate("/renard/onboarding", { state: { step: 3 } });
    }
    if (value === "Certifications / Habilitations & Scolarité") {
      navigate("/renard/onboarding", { state: { step: 4 } });
    }
    if (value === "Informations administratives") {
      navigate("/renard/onboarding", { state: { step: 5 } });
    }
  };
  return (
    <Box display="flex" gap="24px" flexDirection="column">
      <Box>
        <Typography fontWeight="700" fontSize="28px" lineHeight="44.8px">
          Gérer votre profil
        </Typography>
      </Box>
      {!loading && UserFetch ? (
        <Box display="flex" flexDirection="column" gap="0px">
          {!colorSelector() && (
            <Box borderRadius="6px 6px 0px 0px" width="100%" bgcolor="#000B30" padding="40px 37px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }} display="flex" justifyContent="space-between">
              <Typography fontWeight="600" color="white" fontSize="22px" lineHeight="35.2px">
                🚀 Obtenez un accès complet en complétant votre profil.
              </Typography>

              <Button onClick={() => handleClickButton(stepSelector())} sx={{ marginTop: { xs: "20px", md: "0px", lg: "0px", sm: "0px" }, marginLeft: "10px" }} variant="mj_primary_md">
                Suivant: {stepSelector()} <img src={ArrowRightIcon} alt="arrow right icon" style={{ marginLeft: "10px" }} />
              </Button>
            </Box>
          )}
          {colorSelector() && (
            <Box borderRadius="6px 6px 0px 0px" width="100%" bgcolor="#EDFCF5" padding="40px 37px" display="flex" justifyContent="space-between">
              <Typography fontWeight="600" fontSize="22px" lineHeight="35.2px">
                ✅ Compte complété
              </Typography>
            </Box>
          )}

          <Box display="flex">
            <Box borderRight="2px solid white" borderRadius="0px 0px 0px 6px" height="12px" width="20%" backgroundColor={colorSelector() ? "#49E59F" : UserFetch?.valid_steps?.step_1 ? "#0096FF" : "lightblue"}></Box>
            <Box borderRight="2px solid white" height="12px" width="20%" backgroundColor={colorSelector() ? "#49E59F" : UserFetch?.valid_steps?.step_2 ? "#0096FF" : "lightblue"}></Box>
            <Box borderRight="2px solid white" height="12px" width="20%" backgroundColor={colorSelector() ? "#49E59F" : UserFetch?.valid_steps?.step_3 ? "#0096FF" : "lightblue"}></Box>
            <Box borderRight="2px solid white" height="12px" width="20%" backgroundColor={colorSelector() ? "#49E59F" : UserFetch?.valid_steps?.step_4 ? "#0096FF" : "lightblue"}></Box>
            <Box borderRadius="0px 0px 6px 0px" height="12px" width="20%" backgroundColor={colorSelector() ? "#49E59F" : UserFetch?.valid_steps?.step_5 ? "#0096FF" : "lightblue"}></Box>
          </Box>
        </Box>
      ) : (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      <Box display="flex" width="100%" gap="24px" justifyContent="space-between" marginBottom="20px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
        <Box flex="3" display="flex" flexDirection="column" gap="24px">
          <InfosPersonnelle updateBord={updateBord} setUpdateBoard={setUpdateBoard} />
          <InfoAdministrative updateBord={updateBord} setUpdateBoard={setUpdateBoard} />
          <Documents userF={usert} />
        </Box>
        <Box flex="6" display="flex" flexDirection="column" gap="24px">
          <Metier updateBord={updateBord} setUpdateBoard={setUpdateBoard} />
          <MetierCV updateBord={updateBord} setUpdateBoard={setUpdateBoard} />
          <Certification updateBord={updateBord} setUpdateBoard={setUpdateBoard} />
          <Scolarite updateBord={updateBord} setUpdateBoard={setUpdateBoard} />
        </Box>

        {/* CONTENT*/}
      </Box>
    </Box>
  );
}

const StyledLabel = styled;

export default ProfileCandidat;
