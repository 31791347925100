import React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import { Box, Button, Typography, CardContent, Divider, TextField, InputAdornment, Grid, Autocomplete, Modal } from "@mui/material";
import "dayjs/locale/fr";
import leftArrowIcon from "../../../imgs/imgV2/leftArrowBlack.svg";
import arrowRightIcon from "../../../imgs/imgV2/arrowRightBlavk.svg";

import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import RechercheIcon from "../../../imgs/imgV2/RechercheIcon.svg";
import { ToastContainer } from "react-toastify";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import MyTextField from "../../../components/ui/MyTextField";
import MissionComponent from "../../../components/ui/MissionComponent";
function ListeMissionsRenard() {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [search, setSearch] = useState("");
  const [missions, setmissions] = useState([]);
  const [status, setStatus] = useState("");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Api.get(Urls.GET_LIST_MISSION, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: { ...(search !== "" && { search: search }), ...(status !== "" && { status: status }) },
    })
      .then((res) => {
        //console.log(res, "mission");
        setmissions(res?.data?.missions);
        setData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        //console.log(err);
        setLoading(false);
      });
  }, [search, status]);
  const handlePagination = (link) => {
    //console.log("getting data");
    Api.get(`${link}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setData(res?.data);
        setmissions(res.data?.mission);
      })
      .catch((err) => {
        //console.log(err);
        setmissions([]);
      });
  };
  return (
    /*<Box display="flex" direction="row" justifyContent="space-between" sx={{ flexDirection: { xs: "column", sm: "column", md: "row" }, "&>:nth-of-type(2)": { xs: { order: "1" }, md: { order: "2" } }, "&>:nth-of-type(1)": { xs: { order: "2" } } }}>
      {/* CONTENT
      <Box sx={{ margin: { xs: "1rem 0rem 0rem 0rem", md: "1rem" } }} flex={3}>
        <MainTitle icon="missions" iconWidth="30" title="Missions" size="h4" />
        <Box>
          <MainTitle size="h5" title="Liste des missions en cours" />
        </Box>
        {loading === false ? (
          missions.map((o) => (
            <StyledCard onClick={() => handleClickCard(o.id, o.intitule_poste)} key={o.id}>
              <CardContent sx={{ padding: "1rem !important" }}>
                {/* intitulé 
                <Box>
                  <Typography sx={{ overflowWrap: "break-word", whiteSpace: "line", maxWidth: "600px", display: "block", marginBottom: "0.35rem" }} fontSize="1.5rem">
                    {o.intitule_poste}
                  </Typography>
                </Box>
                {/* description 
                <Box sx={{ marginBottom: "0.45rem" }}>
                  <Box sx={{ marginBottom: "0.2rem" }}>
                    <Typography fontSize="1rem">Description</Typography>
                  </Box>

                  <Typography sx={{ overflowWrap: "break-word", whiteSpace: "line", maxWidth: "600px", display: "block" }} variant="text_gray">
                    {o.description ? o.description.slice(0, 200) + "..." : "Aucune description"}
                  </Typography>
                </Box>
                {/* date début date fin 
                <Box sx={{ marginBottom: "0.45rem" }}>
                  <Typography fontSize="1rem">
                    Du {o.planning.start_date_time ? o.planning.start_date_time.split(" ")[0].split("-")[2] + "/" + o.planning.start_date_time.split(" ")[0].split("-")[1] + "/" + o.planning.start_date_time.split(" ")[0].split("-")[0] : null} au{" "}
                    {o.planning.end_date_time ? o.planning.end_date_time.split(" ")[0].split("-")[2] + "/" + o.planning.end_date_time.split(" ")[0].split("-")[1] + "/" + o.planning.end_date_time.split(" ")[0].split("-")[0] : null}
                  </Typography>
                </Box>
                {/* lieu 
                <Box sx={{ marginBottom: "0.45rem" }}>
                  <Typography fontSize="1rem">
                    {o?.location?.parent?.name}, {o?.location?.name}
                  </Typography>
                </Box>
                {/* Taux horaire 
                <Box sx={{ marginBottom: "0.45rem" }}>
                  <Typography color={colors.main} fontSize="1rem" fontWeight="800">
                    Taux horaire {o.taux_horaire_base} €/h
                  </Typography>
                </Box>
              </CardContent>
            </StyledCard>
          ))
        ) : (
          <Stack direction="row" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Stack>
        )}
        {data?.links?.next ? (
          <Stack sx={{ marginBottom: "10px", marginTop: "30px" }} direction="row" justifyContent="center" alignItems="center">
            <Button var="mj_secondary" onClick={handlePagination}>
              Charger plus...
            </Button>
          </Stack>
        ) : null}
      </Box>
      {/*RIGHTBAR
      <MissionCandidatRightBar />

    </Box>
        */
    <Box display="flex" gap="24px" flexDirection="column">
      <ToastContainer />
      <Box
        marginBottom="0px"
        display="flex"
        sx={{
          flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
          justifyContent: { sx: "center", sm: "center", md: "space-between", lg: "space-between" },
          alignItems: { sm: "center", xs: "center", md: "inherit", lg: "inherit" },
          gap: { sm: "10px", xs: "10px", md: "0px", lg: "0px" },
        }}
      >
        <Typography fontWeight="700" fontSize="28px" lineHeight="44.8px">
          Mes missions
        </Typography>
      </Box>
      {/* filter */}
      <Box sx={{ backgroundColor: "#FAFAFB", padding: "40px", borderRadius: "6px" }}>
        <Box>
          <Grid container spacing="16px">
            <Grid item xs={12} md={9}>
              <Box sx={{ marginBottom: "6px" }}>
                <Typography variant="text_normal" fontWeight="600" htmlFor="lieu" name="lieu">
                  Rechercher une mission
                </Typography>
              </Box>
              <MyTextField
                fullWidth
                autoComplete="off"
                type="input"
                placeholder="Rechercher"
                onChange={(event) => {
                  if (event.target.value) {
                    setSearch(event.target.value);
                  } else {
                    setSearch("");
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={RechercheIcon} alt="end" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ marginBottom: "6px" }}>
                <Typography variant="text_normal" fontWeight="600" htmlFor="lieu" name="lieu">
                  État
                </Typography>
              </Box>
              <Autocomplete
                //options={[offerStates]}
                options={[
                  { id: 1, name: "Active", va: "ACTIVE" },
                  { id: 2, name: "Terminée", va: "FINISHED" },
                ]}
                getOptionLabel={(e) => e.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, value) => {
                  if (value !== null) {
                    //console.log("selecting offer state value", value);
                    //console.log("selecting offer state value", value.id);
                    setStatus(value ? value.va : "");
                    //console.log(value);
                    //console.log("selectedOfferState");
                  } else {
                    //console.log(null);
                    setStatus("");
                  }
                }}
                renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Tous" />}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      {!loading ? (
        <Box>
          {missions?.map((mission) => (
            <MissionComponent mission={mission} />
          ))}
        </Box>
      ) : (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {missions?.length > 0 && <Divider />}
      {missions?.length === 0 && !loading && <Typography textAlign="center">Aucune mission</Typography>}
      {missions?.length > 0 && !loading && (
        <Box marginBottom="24px" display="flex" alignItems="center" gap="20px" justifyContent="space-between">
          <Box flex={1}>
            {data?.links?.prev && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  variant="mj_secondary_md"
                >
                  <img style={{ marginRight: "8px" }} src={leftArrowIcon} alt="arrow-left" /> Précédent
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);

                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={leftArrowIcon} alt="arrow-left" />
                </Button>
              </>
            )}
          </Box>
          <Box sx={{ flex: { xs: 8, sm: 8, md: 6, lg: 6 } }} display="flex" gap="10px" justifyContent="center">
            {data?.meta?.links?.map((link, index) => (
              <>
                {index !== 0 && index !== data?.meta?.last_page + 1 && (
                  <ButtonSquare
                    onClick={() => {
                      handlePagination(link.url);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                    action={link?.active ? "number-active" : "number"}
                    number={link.label}
                  />
                )}
              </>
            ))}
          </Box>
          <Box flex={1}>
            {data?.links?.next && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  variant="mj_secondary_md"
                >
                  Suivant <img style={{ marginLeft: "8px" }} src={arrowRightIcon} alt="arrow-right" />
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={arrowRightIcon} alt="arrow-right" />
                </Button>
              </>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default ListeMissionsRenard;
