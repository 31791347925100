import React from "react";

import styled from "@emotion/styled";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Button, Typography, Divider, TextField, InputAdornment, Grid, Autocomplete, Modal, FormControlLabel, FormControl, RadioGroup, Radio } from "@mui/material";
import "dayjs/locale/fr";
import leftArrowIcon from "../../../imgs/imgV2/leftArrowBlack.svg";
import arrowRightIcon from "../../../imgs/imgV2/arrowRightBlavk.svg";
import candIcon from "../../../imgs/imgV2/iconCandidatures.svg";
import propositionIcon from "../../../imgs/imgV2/propIcon.svg";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import RechercheIcon from "../../../imgs/imgV2/RechercheIcon.svg";
import { ToastContainer, toast } from "react-toastify";
import CandidatureComponent from "../../../components/ui/CandidatureComponent";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import MyTextField from "../../../components/ui/MyTextField";

function MesCandidatures() {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [candidatures, setCandidatures] = useState([]);
  const [proposed, setProposed] = useState(false);
  const [data, setData] = useState({});
  const [selectedCandidatureState, setSelectedCandidatureState] = useState(null);
  const [selectedOfferState, setSelectedOfferState] = useState(null);
  const [searchedCandidature, setSearchedCandidature] = useState(null);

  const [change, setChange] = useState(false);
  const [openAccepter, setOpenAccepter] = useState(false);
  const [openAnnuler, setOpenAnnuler] = useState({
    etat: true,
    id: null,
  });
  const [openRefuser, setOpenRefuser] = useState(false);
  const [loading, setLoading] = useState(true);
  //GET CANDIDATURES RENARD
  useEffect(() => {
    Api.get(Urls.GET_LISTE_CANDIDATURES + user?.user?.id + "/applications", {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        in_progress: 1,
        proposed: proposed ? 1 : 0,
        perPage: "5",
        ...(selectedCandidatureState && { status: [selectedCandidatureState] }),
        ...(searchedCandidature && { job_title: searchedCandidature }),
        ...(selectedOfferState && { onlyMatched: selectedOfferState }),
      },
    })
      .then((res) => {
        //console.log("candidatures", res.data);
        //console.log("data for pagination", res.data.meta);
        setCandidatures(res.data?.applications);
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        //console.log(err);
        setLoading(false);
      });
  }, [selectedCandidatureState, selectedOfferState, searchedCandidature, change, proposed]);
  //STYLING CARD
  const StyledCard = styled(Card)({
    cursor: "pointer",
    marginBottom: "1.5rem",
    borderRadius: "20px",
    boxShadow: "0px 6px 8px -6px rgba(0,0,0,0.38)",
    border: "1px solid rgb(247,247,247)",

    "&:hover": {
      border: "1px solid lightgray",
      boxShadow: "0px 6px 8px -4px rgba(0,0,0,0.35)",
      transition: "0.2s",
    },
    curson: "pointer",
  });
  //translation
  const translations = {
    accepted: "Acceptée",
    "pre-selected": "Présélectionnée",
    pending: "En attente",
    confirmed: "Confirmée",
    refused: "Refusée",
    mission: "Mission",
    signed: "Signée",
    Canceled: "Annulée",
  };
  //handle Pagination
  const handlePagination = (link) => {
    //console.log("getting data");
    Api.get(`${link}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        //console.log("RESULTAT MATCHED offres DATA", res?.data);
        //console.log("RESPONES GHERBI", res);
        setData(res?.data);
        setCandidatures(res.data?.applications);
        //console.log(res?.data?.meta?.links, "link pag");
      })
      .catch((err) => {
        //console.log(err);
        setCandidatures([]);
      });
  };
  //handle click candidature

  const [initialValuesSearch, setInitialValuesSearch] = useState({
    job_title: "",
    cand_state: "",
    type: "",
  });
  //console.log(initialValuesSearch);

  const offerStates = [
    {
      id: 0,
      name: "Tous les types",
    },
    {
      id: 1,
      name: "Offres suggérées",
    },
  ];
  const candidaturesStates = [
    {
      id: null,
      name: "Tous les états",
    },
    {
      id: "RECEIVED_OFFERS",
      name: "Nouvelle offre reçue",
    },
    {
      id: "ACCEPTED",
      name: "Acceptée",
    },
    {
      id: "WAITING_SIGNATURE",
      name: "Signé",
    },
    {
      id: "WAITING_SIGNATURE",
      name: "À signer",
    },
    {
      id: "PENDING",
      name: "En attente",
    },
    {
      id: "OFFER_REJECTED",
      name: "Offre rejetée",
    },
    {
      id: "APPLICATION_REJECTED",
      name: "Candidature rejetée",
    },
  ];
  const [idContrat, setIdContrat] = useState(null);
  // validation schema carte identité

  //motif de refus
  const [motif, setMotif] = useState([]);
  useEffect(() => {
    Api.get(Urls.GET_MOTIF_REFUS, {
      headers: { "content-type": "appication/json", authorization: `Bearer ${user?.access_token}` },
    })
      .then((res) => {
        //console.log(res);
        setMotif(res?.data?.interim_refusal_reasons);
      })
      .catch((err) => {
        //console.log(err);
      });
  }, []);

  const [userFetch, setUSerFetch] = useState();
  useEffect(() => {
    Api.get(Urls.GET_USER_REC, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        //console.log(res, "user");
        setUSerFetch(res?.data?.user);
        setLoading(false);
      })
      .catch((err) => toast.error(err?.response?.data?.message));
  }, [change]);

  const AnnulerCandidature = (id) => {
    Api.post(
      Urls.UPDATE_CANDIDATURE + id,
      {
        candidate_status: "CANCELED",
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
        setChange(!change);
        setOpenAnnuler(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  const RejeterCandidature = (id, selected, motif) => {
    Api.post(
      Urls.UPDATE_CANDIDATURE + id,
      {
        candidate_status: "REFUSED",
        ...(selected === "Autre" && { refusal_reason: motif }),
        ...(selected !== "Autre" && { refusal_reason_id: selected }),
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
        setChange(!change);
        setOpenRefuser(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  const AccepterCandidature = (id, epi_ids, password) => {
    let epi_final = [];
    for (let index = 0; index < epi_ids.length; index++) {
      const element = epi_ids[index];
      epi_final.push(element?.id);
    }
    Api.post(
      Urls.UPDATE_CANDIDATURE + id,
      {
        candidate_status: "SIGNED",
        epi: epi_final,
        password: password,
      },
      {
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${user?.access_token}`,
        },
      }
    )
      .then((res) => {
        //console.log(res);
        toast.success("Offre acceptée");
        setChange(!change);
        setOpenAccepter(false);
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  //console.log("missing certificates", candidatures[0]?.missing_documents);
  return (
    /*<Box display="flex" direction="row" justifyContent="space-between" sx={{ flexDirection: { xs: "column", sm: "column", md: "row" }, "&>:nth-of-type(2)": { xs: { order: "1" }, md: { order: "2" } }, "&>:nth-of-type(1)": { xs: { order: "2" } } }}>
      {/* CONTENT*
      <Box sx={{ margin: { xs: "1rem 0rem 0rem 0rem", md: "1rem" } }} flex={3}>
        <Box>
          <MainTitle size="h4" title="Mes candidatures" icon="c_candidatures" iconWidth="35" margin="0rem 0rem 1.5rem 0rem" />
        </Box>
        <Box>
          <MainTitle title="Liste de mes candidatures" size="h6" />
        </Box>
        {candidatures
          ? candidatures.map((candidature) => (
              <StyledCard onClick={() => handleClickCandidature(candidature.id)} key={candidature.id}>
                <CardContent sx={{ padding: "1rem !important" }}>
                  {/* intitulé *
                  <Box>
                    <Typography fontSize="1.5rem" sx={{ marginBottom: "0.45rem", overflowWrap: "break-word", whiteSpace: "line", maxWidth: "600px", display: "block" }}>
                      {candidature?.offre?.intitule_poste}
                    </Typography>
                    <Box sx={{ marginBottom: "1rem" }}>{candidature?.status ? <Button variant={candidature?.status}>{translations[`${candidature?.status}`]}</Button> : null}</Box>
                    <Box sx={{ marginBottom: "0.45rem" }}>{candidature?.offre?.taux_horaire ? <Typography fontSize="1rem">Taux horaire {candidature?.offre?.taux_horaire}€/h</Typography> : <Typography fontSize="1rem">Non defini</Typography>}</Box>
                  </Box>
                  {/* lieu *
                  <Box sx={{ marginBottom: "0.45rem" }}>
                    <Typography fontSize="1rem">
                      {candidature?.offre?.location?.name ? candidature?.offre?.location?.name : "Non defini"}, {candidature?.offre?.location?.parent?.name ? candidature?.offre?.location?.parent?.name : "Non defini"}
                    </Typography>
                  </Box>
                  <Box sx={{ marginBottom: "0.45rem" }}>
                    <Typography fontSize="1rem">
                      Débute le{" "}
                      {candidature?.offre?.planning?.start_date_time
                        ? candidature?.offre?.planning?.start_date_time.split(" ")[0].split("-")[2] + "/" + candidature?.offre?.planning?.start_date_time.split(" ")[0].split("-")[1] + "/" + candidature?.offre?.planning?.start_date_time.split(" ")[0].split("-")[0]
                        : "Non defini"}{" "}
                      à {candidature?.offre?.planning?.start_date_time ? candidature?.offre?.planning?.start_date_time.split(" ")[1].split(":")[0] + ":" + candidature?.offre?.planning?.start_date_time.split(" ")[1].split(":")[1] : "Non defini"}
                    </Typography>
                  </Box>
                </CardContent>
              </StyledCard>
            ))
          : null}
        {data?.next_page_url ? (
          <Stack sx={{ marginBottom: "10px", marginTop: "30px" }} direction="row" justifyContent="center" alignItems="center">
            <Button variant="mj_secondary" sx={{ borderRadius: "20px" }} onClick={handlePagination}>
              Charger plus...
            </Button>
          </Stack>
        ) : null}
      </Box>

      {/*RIGHTBAR*
      <Box flex={1}></Box>
    </Box>*/
    <Box display="flex" gap="24px" flexDirection="column">
      <ToastContainer />
      <Box
        marginBottom="0px"
        display="flex"
        sx={{
          flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
          justifyContent: { sx: "center", sm: "center", md: "space-between", lg: "space-between" },
          alignItems: { sm: "center", xs: "center", md: "inherit", lg: "inherit" },
          gap: { sm: "10px", xs: "10px", md: "0px", lg: "0px" },
        }}
      >
        <Typography fontWeight="700" fontSize="28px" lineHeight="44.8px">
          Mes candidatures
        </Typography>
        <Box display="flex" justifyContent="flex-end" marginBottom="16px">
          <Button onClick={() => setProposed(false)} variant={proposed === false ? "mj_primary_choice_active" : "mj_primary_choice"}>
            <img src={candIcon} alt="lamp-icon" style={{ marginRight: "4px" }} /> Mes candidatures
          </Button>
          <Button onClick={() => setProposed(true)} variant={proposed === true ? "mj_primary_choice_active" : "mj_primary_choice"}>
            <img src={propositionIcon} alt="lamp-icon" style={{ marginRight: "4px" }} /> Propositions de mission
          </Button>
        </Box>
      </Box>
      {/* filter */}
      <Box sx={{ backgroundColor: "#FAFAFB", padding: "40px", borderRadius: "6px" }}>
        <Box>
          <Grid container spacing="16px">
            <Grid item xs={12} md={6}>
              <Box sx={{ marginBottom: "6px" }}>
                <Typography variant="text_normal" fontWeight="600" htmlFor="lieu" name="lieu">
                  Rechercher une demande
                </Typography>
              </Box>
              <MyTextField
                fullWidth
                autoComplete="off"
                type="input"
                placeholder="Rechercher"
                onChange={(event) => {
                  if (event.target.value) {
                    setSearchedCandidature(event.target.value);
                  } else {
                    setSearchedCandidature(null);
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={RechercheIcon} alt="end" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ marginBottom: "6px" }}>
                <Typography variant="text_normal" fontWeight="600" htmlFor="lieu" name="lieu">
                  État
                </Typography>
              </Box>
              <Autocomplete
                //options={[offerStates]}
                options={offerStates}
                getOptionLabel={(e) => e.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, value) => {
                  if (value !== null) {
                    //console.log("selecting offer state value", value);
                    //console.log("selecting offer state value", value.id);
                    setSelectedOfferState(value ? value.id : "");
                    //console.log(value);
                    //console.log("selectedOfferState");
                  } else {
                    //console.log(null);
                    setSelectedOfferState(null);
                  }
                }}
                renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Tous" />}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ marginBottom: "6px" }}>
                <Typography variant="text_normal" fontWeight="600" htmlFor="lieu" name="lieu">
                  État des candidatures
                </Typography>
              </Box>
              <Autocomplete
                options={candidaturesStates}
                //options={candidaturesStates}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(e) => e.name}
                onChange={(event, value) => {
                  if (value !== null) {
                    //console.log("selecting candidature state value", value);
                    //console.log("selecting candidature state value", value.id);
                    setSelectedCandidatureState(value ? value.id : "");
                    //console.log(value);
                    //console.log("selectedCandidatureState");
                  } else {
                    setSelectedCandidatureState(null);
                    //console.log(null);
                  }
                }}
                renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Tous" />}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      {!loading ? (
        <Box display="flex" flexDirection="column">
          {candidatures?.map((cand, index) => (
            <CandidatureComponent openAnnuler={openAnnuler} setOpenAnnuler={setOpenAnnuler} openAccepter={openAccepter} openRefuser={openRefuser} setOpenRefuser={setOpenRefuser} setOpenAccepter={setOpenAccepter} key={cand?.id} change={change} setChange={setChange} motif={motif} loading={loading} userF={userFetch} missing_documents={cand?.missing_documents} RejeterCandidature={RejeterCandidature} AnnulerCandidature={AnnulerCandidature} AccepterCandidature={AccepterCandidature} etat={cand?.status} id={cand?.id} color={cand?.interim_actions?.length > 0 ? "#FDEFE7" : "white"} matchingLevel={cand?.matching_level} contrat={cand?.contract} offreName={cand?.offer?.job_title} possibleActions={cand?.interim_actions} offreID={cand?.offer?.id} createdAt={cand?.created_at} start_date={cand?.offer?.start_date} end_date={cand?.offer?.end_date} hourly_rate={cand?.offer?.basic_hourly_rate} address={cand?.offer?.location_address} />
          ))}
        </Box>
      ) : (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {candidatures.length > 0 && <Divider sx={{ margin: "24px 0px" }} />}
      {candidatures?.length === 0 && !loading && <Typography textAlign="center">Aucune candidature</Typography>}
      {candidatures?.length > 0 && (
        <Box marginBottom="24px" display="flex" alignItems="center" gap="20px" justifyContent="space-between">
          <Box flex={1}>
            {data?.links?.prev && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  variant="mj_secondary_md"
                >
                  <img style={{ marginRight: "8px" }} src={leftArrowIcon} alt="arrow-left" /> Précédent
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);

                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={leftArrowIcon} alt="arrow-left" />
                </Button>
              </>
            )}
          </Box>
          <Box sx={{ flex: { xs: 8, sm: 8, md: 6, lg: 6 } }} display="flex" gap="10px" justifyContent="center">
            {data?.meta?.links?.map((link, index) => (
              <>
                {index !== 0 && index !== data?.meta?.last_page + 1 && (
                  <ButtonSquare
                    onClick={() => {
                      handlePagination(link.url);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                    action={link?.active ? "number-active" : "number"}
                    number={link.label}
                  />
                )}
              </>
            ))}
          </Box>
          <Box flex={1}>
            {data?.links?.next && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  variant="mj_secondary_md"
                >
                  Suivant <img style={{ marginLeft: "8px" }} src={arrowRightIcon} alt="arrow-right" />
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={arrowRightIcon} alt="arrow-right" />
                </Button>
              </>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}
export default MesCandidatures;
