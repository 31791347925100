import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import Stack from "@mui/material/Stack";
import { colors } from "../../../../theme/Colors";
import { Box, Typography, Grid, TextField, Autocomplete, Button, InputAdornment, Modal } from "@mui/material";
import RechercheIcon from "../../../../imgs/imgV2/RechercheIcon.svg";
import styled from "@emotion/styled";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import MyChip from "../../../../components/ui/MyChip";
import RightArrowTime from "../../../../imgs/imgV2/RightArrowTime.svg";
import leftArrowIcon from "../../../../imgs/imgV2/leftArrowBlack.svg";
import ButtonSquare from "../../../../components/ui/ButtonSquare";
import arrowRightIcon from "../../../../imgs/imgV2/arrowRightBlavk.svg";
import MainTitle from "../../../../components/ui/MainTitle";
import Api from "../../../../api/Api";
import { Urls } from "../../../../api/Urls";
import MySelect from "../../../../components/ui/MySelect";
import { useUserContext } from "../../../../hooks/useUserContext";
import lockIcon from "../../../../imgs/imgV2/lockIcon.svg";
import alertIcon from "../../../../imgs/imgV2/alertIcon.svg";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { modalStyle } from "../../../../theme/ModalStyle";
import BoxContainer from "../../../../components/ui/BoxContainer";

const MyTextField = styled(TextField)({
  width: "100%",
});

function formatDateFront(date) {
  moment.locale("fr");
  return moment(date)
    .format("DD MMMM YYYY")
    .replace(/\b\w/g, (match) => match.toUpperCase());
}

function isEqualDate(date1, date2) {
  return date1.getDate() >= date2.getDate() && date1.getMonth() >= date2.getMonth() && date1.getFullYear() >= date2.getFullYear();
}

function afficherDifferenceDate(date) {
  const now = moment();
  const diff = moment(date);
  const minutes = now.diff(diff, "minutes");
  const hours = now.diff(diff, "hours");
  const jours = now.diff(diff, "days");
  const semaines = now.diff(diff, "weeks");
  const mois = now.diff(diff, "months");

  if (minutes === 1) {
    return "Postée il y a 1 minute";
  } else if (minutes > 1 && minutes < 60) {
    return `Postée il y a ${minutes} minutes`;
  }
  if (hours === 1) {
    return "Postée il y a 1 heure";
  } else if (hours > 1 && hours < 24) {
    return `Postée il y a ${hours} heures`;
  }
  if (jours === 1) {
    return "Postée il y a 1 jour";
  } else if (jours > 1 && jours < 7) {
    return `Postée il y a ${jours} jours`;
  } else if (semaines === 1) {
    return "Postée il y a 1 semaine";
  } else if (semaines > 1 && semaines < 4) {
    return `Postée il y a ${semaines} semaines`;
  } else if (mois === 1) {
    return "Postée il y a 1 mois";
  } else {
    return `Postée il y a ${mois} mois`;
  }
}

function MesOffres() {
  const { user } = useAuthContext();
  const navigate = useNavigate();

  const access_token = user?.access_token;
  const idUser = user?.user?.id;

  //filter states
  const [searchedOffer, setSearchedOffer] = useState(null);
  const [selectedOfferState, setSelectedOfferState] = useState(null);
  const [selectedCandidatureState, setSelectedCandidatureState] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openCloturer, setOpenCloturer] = useState(false);
  const [numberOfResults, setNumberOfResults] = useState();
  const [offers, setOffers] = useState([]);
  const [openActive, setOpenActive] = useState(false);
  const [data, setData] = useState();
  const [change, setChange] = useState(false);
  const [id, setId] = useState(null);
  useEffect(() => {
    Api.get(Urls.GET_OFFRE_LIST, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        isActive: selectedOfferState,
        isApplicationOpen: selectedCandidatureState,
        searchKey: searchedOffer,
      },
    })
      .then((response) => {
        //console.log(response?.data?.offers, "offers");
        setOffers(response?.data?.offers);
        setData(response?.data);
        setLoading(false);
      })
      .catch((error) => {
        //console.log(error);
        setLoading(false);
      });
  }, [searchedOffer, selectedOfferState, selectedCandidatureState, change]);

  //handle Pagination
  const handlePagination = (link) => {
    //console.log("getting data");
    Api.get(`${link}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        //console.log("RESULTAT MATCHED offres DATA", res?.data);
        //console.log("RESPONES GHERBI", res);
        //console.log(res?.data?.offers[0], " offer 1 : matching level");
        setData(res?.data);
        setOffers(res.data?.offers);
        setNumberOfResults(res?.data?.meta?.total);
        //console.log(res?.data?.meta?.links, "link pag");
      })
      .catch((err) => {
        //console.log(err);

        setOffers([]);
      });
  };

  const offerStates = [
    {
      id: null,
      name: "Tous les états",
    },
    {
      id: 1,
      name: "Actives",
    },
    {
      id: 0,
      name: "Terminées",
    },
  ];

  const candidaturesStates = [
    {
      id: null,
      name: "Tous les états",
    },
    {
      id: 1,
      name: "Candidatures ouvertes",
    },
    {
      id: 0,
      name: "Candidatures fermées",
    },
  ];

  const initialValues = {
    offer: "",
    offer_state: "",
    candidature_state: "",
  };

  const validationSchema = Yup.object({
    offer: Yup.string(),
    offer_state: Yup.string(),
    candidature_state: Yup.string(),
  });
  //accept offer
  const activateOffer = (status) => {
    Api.post(
      Urls.ACTIVATE_DEACTIVATE_DEMANDE + id + "/activate-or-deactivate",
      {
        is_active: status,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        //console.log(res.data);
        setOpenCloturer(false);
        toast.success(res?.data?.message);
        setOpenActive(false);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err.messages);
        toast.error(err?.response?.data.message);
      });
  };
  const OfferItem = ({ created_at, id, status, isLocked, title, postedSince, startDate, endDate, nbr_new_profiles, nbr_total_hires, nbr_total_profiles, hasUndeclaredHours, profiles, interview, recruted, isFirst, isLast, ...props }) => {
    const chipSelector = (status) => {
      switch (status) {
        case 1:
          return { variant: "active_offer", label: "Active" };
        case 0:
          return { variant: "ended_offer", label: "Terminée" };
        default:
          return {};
      }
    };

    return (
      <Box
        {...props}
        sx={{
          ":hover": { filter: "brightness(0.95)", background: "white" },
          cursor: "pointer",
          padding: "40px",
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          border: "1px solid #DEDFE4",
          marginTop: "-1px",
          justifyContent: { md: "space-between" },
          gap: "16px",
          alignItems: { md: "center" },
        }}
        borderRadius={isFirst ? "6px 6px 0px 0px" : isLast ? "0px 0px 6px 6px " : "0px 0px 0px 0px"}
      >
        {/* infos left */}
        <Box flex={6} onClick={() => navigate(`/entreprise/offres/${id}`, { state: { application_open: isLocked, title: title, status: status, postedSince: afficherDifferenceDate(created_at) } })} display="flex" flexDirection="column">
          <Box display="flex" flexDirection="row" gap="8px" sx={{ marginBottom: "4px" }} alignItems="center">
            <MyChip variant="id" label={`#${id}`} />
            {(status || status === 0) && <MyChip variant={chipSelector(status).variant} label={chipSelector(status).label} />}
            {!isLocked && <img src={lockIcon} height="18px" alt="" />}
          </Box>
          <Box>
            <Typography variant="text_md" fontWeight="500">
              {title}
            </Typography>
          </Box>
          <Box>
            <Typography variant="text_normal" color="#4A526C">
              {afficherDifferenceDate(postedSince)}
            </Typography>
          </Box>
          <Box>
            <Box display="flex" flexDirection="row" alignItems="center" gap="6px">
              <Typography variant="text_normal">{isEqualDate(new Date(), moment(startDate).toDate()) ? "Dès que possible" : formatDateFront(startDate)}</Typography>
              <img src={RightArrowTime} width="15px" alt="" />
              <Typography variant="text_normal">{formatDateFront(endDate)}</Typography>
            </Box>
          </Box>
          {hasUndeclaredHours && (
            <Box display="flex" flexDirection="row" gap="5px" alignItems="center">
              <img src={alertIcon} width="14px" alt="" />
              <Typography variant="text_normal" color="#CC0000" fontWeight="500">
                Certaines heures ne sont pas déclarées
              </Typography>
            </Box>
          )}
        </Box>

        {/* infos right */}
        <Box flex={5} display="flex" sx={{ flexDirection: { md: "row", xs: "column" }, gap: { md: "20px", xs: "8px" }, alignItems: { md: "center" } }}>
          <Box flex={4} onClick={() => navigate(`/entreprise/offres/${id}`, { state: { application_open: isLocked, title: title, status: status, postedSince: afficherDifferenceDate(created_at) } })} display="flex" flexDirection="row">
            <Box displa="flex" flexDirection="column">
              <Box width="120px">
                <Typography variant="text_normal" display="inline" color="black">
                  {nbr_total_profiles}
                  <Typography sx={{ display: `${nbr_new_profiles === 0 ? "none" : "inline"}` }} fontWeight="500" fontSize="14px" lineHeight="22.4px" color="#0065FF">
                    ({nbr_new_profiles} nouv.)
                  </Typography>
                </Typography>
              </Box>
              <Box width="120px">
                <Typography variant="text_normal" color="#666D83">
                  profils
                </Typography>
              </Box>
            </Box>
            {/*
            <Box displa="flex" flexDirection="column">
              <Box width="120px">
                <Typography variant="text_normal" color="black">
                  0
                </Typography>
              </Box>
              <Box width="120px">
                <Typography variant="text_normal" color="#666D83">
                  En entretien
                </Typography>
              </Box>
            </Box>
          */}
            <Box onClick={() => navigate(`/entreprise/offres/${id}`, { state: { application_open: isLocked, title: title, status: status, postedSince: afficherDifferenceDate(created_at) } })} displa="flex" flexDirection="column">
              <Box width="120px">
                <Typography variant="text_normal" color="black">
                  {nbr_total_hires}
                </Typography>
              </Box>
              <Box width="120px">
                <Typography variant="text_normal" color="#666D83">
                  Embauchés
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box flex={2} displa="flex" flexDirection="column">
            {status === 1 && (
              <Button
                onClick={() => {
                  setId(id);
                  setOpenCloturer(true);
                }}
                fullWidth
                variant="mj_secondary_sm"
              >
                Cloturer la demande
              </Button>
            )}
            {status === 0 && (
              <Button
                onClick={() => {
                  setId(id);
                  setOpenActive(true);
                }}
                fullWidth
                variant="mj_secondary_sm"
              >
                Activer la demande
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    );
  };
  return (
    <Box display="flex" flexDirection="column" gap="40px">
      <ToastContainer />
      {/* title */}
      <Box>
        <Typography variant="text_xl" fontWeight="700">
          Mes Demande
        </Typography>
      </Box>

      {/* filter */}
      <Box sx={{ backgroundColor: "#FAFAFB", padding: "40px", borderRadius: "6px" }}>
        <Box>
          <Grid container spacing="16px">
            <Grid item xs={12} md={6}>
              <Box sx={{ marginBottom: "6px" }}>
                <Typography variant="text_normal" fontWeight="600" htmlFor="lieu" name="lieu">
                  Rechercher une demande
                </Typography>
              </Box>
              <MyTextField
                fullWidth
                autoComplete="off"
                type="input"
                placeholder="Rechercher"
                onChange={(event) => {
                  if (event.target.value) {
                    setSearchedOffer(event.target.value);
                  } else {
                    setSearchedOffer(null);
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={RechercheIcon} alt="end" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ marginBottom: "6px" }}>
                <Typography variant="text_normal" fontWeight="600" htmlFor="lieu" name="lieu">
                  État
                </Typography>
              </Box>
              <Autocomplete
                options={offerStates}
                getOptionLabel={(e) => e.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, value) => {
                  if (value !== null) {
                    //console.log("selecting offer state value", value);
                    //console.log("selecting offer state value", value.id);
                    setSelectedOfferState(value ? value.id : "");
                    //console.log("selectedOfferState", selectedOfferState);
                  } else {
                    setSelectedOfferState(null);
                  }
                }}
                renderInput={(params) => <TextField placeholder="Tous" {...params} name="autocomplete" />}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ marginBottom: "6px" }}>
                <Typography variant="text_normal" fontWeight="600" htmlFor="lieu" name="lieu">
                  État des candidatures
                </Typography>
              </Box>
              <Autocomplete
                options={candidaturesStates}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(e) => e.name}
                onChange={(event, value) => {
                  if (value !== null) {
                    //console.log("selecting candidature state value", value);
                    //console.log("selecting candidature state value", value.id);
                    setSelectedCandidatureState(value ? value.id : "");
                    //console.log("selectedCandidatureState", selectedCandidatureState);
                  } else {
                    setSelectedCandidatureState(null);
                  }
                }}
                renderInput={(params) => <TextField placeholder="Tous" {...params} name="autocomplete" />}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* list */}
      {!loading && (
        <Box>
          {offers?.length !== 0 ? (
            <Box>
              {offers.map((offer, index) => (
                <OfferItem created_at={offer.created_at} key={index} isFirst={index === 0} isLast={index === offers.length - 1} id={offer.id} status={offer.is_active} isLocked={offer.is_application_open} postedSince={offer.created_at} title={offer.job_title} startDate={offer.start_date} endDate={offer.end_date} hasUndeclaredHours={offer?.some_hours_are_not_declared} nbr_new_profiles={offer?.nbr_new_profiles} nbr_total_hires={offer?.nbr_total_hires} nbr_total_profiles={offer?.nbr_total_profiles} />
              ))}
            </Box>
          ) : (
            <Box display="flex" justifyContent="center">
              <Typography>Aucun résultat trouvé</Typography>
            </Box>
          )}
        </Box>
      )}
      {loading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {/* pagination  */}
      {offers?.length > 0 && (
        <Box display="flex" alignItems="center" gap="20px" justifyContent="space-between" sx={{ padding: "20px 0px 102px 0px", borderTop: "1px solid #DEDFE4" }}>
          <Box flex={1}>
            {data?.links?.prev && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  variant="mj_secondary_md"
                >
                  <img style={{ marginRight: "8px" }} src={leftArrowIcon} alt="arrow-left" /> Précédent
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);

                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={leftArrowIcon} alt="arrow-left" />
                </Button>
              </>
            )}
          </Box>
          <Box sx={{ flex: { xs: 8, sm: 8, md: 6, lg: 6 } }} display="flex" gap="10px" justifyContent="center">
            {data?.meta?.links?.map((link, index) => (
              <>
                {index !== 0 && index !== data?.meta?.last_page + 1 && (
                  <ButtonSquare
                    onClick={() => {
                      handlePagination(link.url);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                    action={link?.active ? "number-active" : "number"}
                    number={link.label}
                  />
                )}
              </>
            ))}
          </Box>
          <Box flex={1}>
            {data?.links?.next && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  variant="mj_secondary_md"
                >
                  Suivant <img style={{ marginLeft: "8px" }} src={arrowRightIcon} alt="arrow-right" />
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={arrowRightIcon} alt="arrow-right" />
                </Button>
              </>
            )}
          </Box>
        </Box>
      )}
      <Modal
        open={openCloturer}
        onClose={() => {
          setOpenCloturer(false);
        }}
      >
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Clotuer la demande
              </Typography>
              <ButtonSquare onClick={() => setOpenCloturer(false)} action="close" />
            </Box>
            <Box display="flex" flexDirection="column" gap="24px">
              <Box>
                <Typography sx={{ fontSize: "14px", fontWeight: "600", lineHeight: "160%" }}>Êtes-vous sûr de bien vouloir cloturer la demande</Typography>
              </Box>
              <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                <Button onClick={() => setOpenCloturer(false)} variant="mj_secondary_md">
                  Annuler
                </Button>
                <Button onClick={() => activateOffer(0)} variant="mj_danger_md">
                  Confirmer
                </Button>
              </Box>
            </Box>
          </BoxContainer>
        </Box>
      </Modal>
      <Modal
        open={openActive}
        onClose={() => {
          setOpenActive(false);
        }}
      >
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Activer la demande
              </Typography>
              <ButtonSquare onClick={() => setOpenActive(false)} action="close" />
            </Box>
            <Box display="flex" flexDirection="column" gap="24px">
              <Box>
                <Typography sx={{ fontSize: "14px", fontWeight: "600", lineHeight: "160%" }}>Êtes-vous sûr de bien vouloir activer la demande</Typography>
              </Box>
              <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                <Button onClick={() => setOpenActive(false)} variant="mj_secondary_md">
                  Annuler
                </Button>
                <Button onClick={() => activateOffer(1)} variant="mj_danger_md">
                  Confirmer
                </Button>
              </Box>
            </Box>
          </BoxContainer>
        </Box>
      </Modal>
    </Box>
  );
}

export default MesOffres;
